import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

export const GlobalStyles = createGlobalStyle`
  ${styledNormalize}

  body {
    margin: 0;
    font-family: 'Roboto';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 1.42857143;
  }

  html, body, #root {
    height:100%;
  }

  .clickable {
    cursor: pointer;
  }

  body > iframe {
    display: none;
  }

  * img {
    object-fit: cover;
  }
`;
