import { usePaginatedSWR, useSWR } from '../hooks'

export const list = ({ fetcher }) => (initialPage = 1, initialPageSize = 10) => usePaginatedSWR(
  'global/epis', null, initialPage, initialPageSize, (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1 } })
)

export const listActive =  ({ fetcher }) => () => useSWR(
  'global/epis?active=1',(url) => fetcher.get(url).then(res => res.epis),
  { revalidateOnFocus: false }
)

export const get = ({ fetcher }) => (id) => useSWR(id && `global/epis/${id}`,
  (url) => fetcher.get(url).then(res => res.epi),
  { revalidateOnFocus: false }
)

export const update = ({ fetcher }) => async epi => {
  const data = {
    ...epi
  }

  await fetcher.put(`global/epis/${epi.id}`, data)
}

export const create = ({ fetcher }) => epi => fetcher.post('global/epis', epi)

export const remove = ({ fetcher }) => epi => fetcher.delete(`global/epis/${epi.id}`)
