import { usePaginatedSWR, useSWR } from '../hooks'

export const list = ({ fetcher }) => ({filter = {}, initialPage = 1, initialPageSize = 10}) => usePaginatedSWR(
  '/userauth/users', filter, initialPage, initialPageSize, (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, active: 1, ...filter } })
)

export const get = ({ fetcher }) => (id) => useSWR(id && `/userauth/users?id=${id}`,
  (url) => fetcher.get(url).then(res => res && res.users && res.users.length > 0 && res.users[0]),
  { revalidateOnFocus: false }
)

export const update = ({ fetcher }) => async user => {
  const data = {
    ...user
  }
  delete data.profiles
  delete data.places
  delete data.created_at
  delete data.updated_at
  await fetcher.put(`/userauth/users/${user.id}`, data)
}

export const create = ({ fetcher }) => user => fetcher.post('/userauth/users', user)

export const remove = ({ fetcher }) => id => fetcher.delete(`/userauth/users/${id}`)

export const getPlaces = ({ fetcher }) => (userId) => useSWR(userId && `userauth/users/${userId}/places`,
  (url) => fetcher.get(url).then(response => {
    const responsePlaces = response.places
    const elements = responsePlaces.map( item => (
        {
          associationId: item.user_places[0] && item.user_places[0].id,
          name: item.name,
          id: item.id
        }
      ))

    const selectedPlaceIds = elements.filter( item => item.associationId).map( item => item.id )

    return {elements, selectedPlaceIds}
  })
)

export const linkToPlaces = ({ fetcher }) => async (user, selectedPlaces) => {
  const data = {
    places: selectedPlaces.map( place => ({
      places_id: place.id
    }))
  }

  await fetcher.post(`userauth/users/${user.id}/places`, data)
}

export const linkToProfiles = ({ fetcher }) => async (user, selectedProfiles) => {
  const data = {
    profiles: selectedProfiles.map( profile => ({
      profiles_id: profile.id
    }))
  }

  await fetcher.post(`userauth/users/${user.id}/profiles`, data)
}

export const getProfiles = ({ fetcher }) => (userId) => useSWR(userId && `userauth/users/${userId}/profiles`,
  (url) => fetcher.get(url).then(response => {
    const responseProfiles = response.profiles
    const elements = responseProfiles.map( item => (
        {
          associationId: item.user_profiles[0] && item.user_profiles[0].id,
          name: item.name,
          id: item.id
        }
      ))

    const selectedProfileIds = elements.filter( item => item.associationId).map( item => item.id )
    return {elements, selectedProfileIds}
  })
)

export const report = ({ fetcher, authentication }) => ({filter = {}, initialPage = 1, initialPageSize = 10}) => usePaginatedSWR(
  `/remote_assistant/users/${authentication.user.id}/contacts`, filter, initialPage, initialPageSize,
  (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, ...filter } })
)
