import { usePaginatedSWR, useSWR } from '../hooks'

export const list = ({ fetcher }) => ({filter = {}, constructionId , initialPage = 1, initialPageSize = 10}) => usePaginatedSWR(
  `/constructions/${constructionId}/activities`, filter, initialPage, initialPageSize, (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, active: 1, ...filter } })
)

export const get = ({ fetcher }) => ({activityId, constructionId}) => useSWR(activityId && constructionId && `/constructions/${constructionId}/activities/${activityId}`,
  (url) => fetcher.get(url).then(res => res && res.construction_activity),
  { revalidateOnFocus: false }
)

export const update = ({ fetcher }) => async constructionActivity => {
  const data = {
    ...constructionActivity
  }
  delete data.created_at
  delete data.updated_at
  await fetcher.put(`/constructions/${constructionActivity.construction.id}/activities/${constructionActivity.id}`, data)
}

export const create = ({ fetcher }) => async ({constructionActivity, constructionId}) => {
  fetcher.post(`constructions/${constructionId}/activities`, constructionActivity)
}

export const remove = ({ fetcher }) => ({activityId, constructionId}) => fetcher.delete(`/constructions/${constructionId}/activities/${activityId}`)

