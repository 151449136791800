import React from 'react'
import Icon from '@ant-design/icons'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as InternalFontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'

const Container = styled.div`
  font-size: 16px;
  color: #064591;
  font-weight: light;
  margin-bottom: 10px;
  margin-top: 10px;
  span {
    margin-right: 10px;
  }
`

const MenuIcon = (props) => {
  return(<Icon component={ () => <InternalFontAwesomeIcon {...props} />} /> )
}

export const FormSection = ({title}) => {
  return (<Container><MenuIcon icon={faEdit} />{title}</Container>)
}



