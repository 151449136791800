import { useState } from 'react'

export const Hoverable = ({ render, onEnter: onEnterOriginal, onLeave: onLeaveOriginal }) => {
  const [hovering, setHovering] = useState(false)

  const onEnter = _ => {
    setHovering(true)
    onEnterOriginal && onEnterOriginal()
  }
  const onLeave = _ => {
    setHovering(false)
    onLeaveOriginal && onLeaveOriginal()
  }

  return render({ hovering, onEnter, onLeave })
}
