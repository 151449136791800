import React, { useState } from 'react'
import { Space, Modal, Select, notification  } from 'antd'
import { useTranslation } from 'react-i18next'
import { FormButton } from '../Page/FormButton'

export const CustomersModal = ({ visible, onModalClose, customers, selectCustomer }) => {
  const { t } = useTranslation()

  const [ selectedCustomerId, setSelectedCustomerId ] = useState(null)
  const { Option } = Select

  const sendSelectedCustomer = () => {

    if (selectedCustomerId == null){
      notification.error({
        message: t('CustomerModal.SelectError'),
        duration: 3
      })
    }else{
      selectCustomer(selectedCustomerId)
    }
  }

  return (
    <Modal
      width={'min-content'}
      title={t('CustomerModal.SelectCustomer')}
      footer={null}
      visible={visible}
      onCancel={onModalClose}>
      <Space direction='vertical' align='center'>

      <Select
        placeholder="Cliente"
        showSearch
        style={{
          width: 300,
        }}
        optionFilterProp='children'
        filterOption={(input, option) =>
        (option.children &&
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
        }
        value={selectedCustomerId}
        onChange={(id) => { setSelectedCustomerId(id) }}>

          <Option key={null} value={null}></Option>
          {customers && customers.map((customer) =>
            <Option key={customer.customer_id} value={customer.customer_id}>{customer.customer_name}</Option>
          )}

      </Select>

      <FormButton onClick={sendSelectedCustomer}>
        {t('CustomerModal.SubmitButton')}
      </FormButton>

      </Space>


    </Modal>
  )
}
