
import { usePaginatedSWR, useSWR } from '../hooks'

export const list = ({ fetcher }) => ({filter = {}, initialPage = 1, initialPageSize = 10}) => usePaginatedSWR(
  '/global/assets', filter, initialPage, initialPageSize, (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, ...filter } })
)

export const activeEquipments = ({ fetcher }) => () => useSWR(`global/assets`,
  (url) => fetcher.get(url).then(res => res.assets),
  { revalidateOnFocus: false }
)

export const create = ({ fetcher }) => equipment => fetcher.post('/global/assets', equipment)

export const update = ({ fetcher }) => async equipment => {
  await fetcher.put(`/global/assets/${equipment.id}`, equipment)
}

export const get = ({ fetcher }) => (id) => useSWR(id && `global/assets/${id}`,
  (url) => fetcher.get(url).then(res => res),
  { revalidateOnFocus: false }
)
export const remove = ({ fetcher }) => id => fetcher.delete(`global/assets/${id}`)

export const uploadMask = ({ fetcher }) => async (equipment, mask) => {
  await fetcher.put(`/global/assets/${equipment.id}/mask`, mask)
}

export const deleteMask = ({ fetcher }) => id => fetcher.delete(`global/assets/${id}/mask`)

export const upload3dImage = ({ fetcher }) => async (equipment, image3d) => {
  await fetcher.put(`/global/assets/${equipment.id}/image3d`, image3d)
}

export const delete3dImage = ({ fetcher }) => id => fetcher.delete(`global/assets/${id}/image3d`)

