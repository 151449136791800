import React from 'react'
import { Space, Button } from 'antd'
import { faTrash, faChalkboard } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import { AwesomeIcon } from '../../components'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export const VideoItem = (props) => {
  const { item, preview, confirmDelete } = props
  const { t } = useTranslation()

  const handleShowVideo = async (file) => {
    if (!file.url_content && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    preview({
      previewImage: file.url_content,
      previewVisible: true,
      previewTitle: `${t('Admin.DynamicInstructions.Upload.Position')} ${file.seq}`,
    })
  }

  return (
    <Space direction='vertical' style={{ marginRight: 15 }}>
      <Button
        disabled={!item.url_content}
        onClick={e => { handleShowVideo(item) }}
        icon={<AwesomeIcon icon={faChalkboard}  />}
        size={15} />

      <Button
        disabled={!item.url_content}
        onClick={e => {
          confirmDelete.open(item)
        }}
        icon={<AwesomeIcon icon={faTrash} />}
        size={15} />
    </Space>
  )
}
