import React from 'react'
import { Upload, notification } from 'antd'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

const convertFileToBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = () => resolve(reader.result)
  reader.onerror = (error) => reject(error)
  reader.readAsDataURL(file)
})

const CustomImg = styled.img`
  width: 100%;
  cursor: pointer;
  max-width: 250px;
  opacity: ${props => props.hasUrl ?? 0.5};
`

export const UploadVideo = (props) => {
  const { item, onUpload, onChange } = props
  const { t } = useTranslation()

  const beforeUpload = async file => {
    const isMp4 = file.type === 'video/mp4'
    onUpload('')

    if (!isMp4) {
      notification.error({
        message: t('Admin.DynamicInstructions.Upload.FormatError'),
        duration: 3
      })

      return false
    }

    const fileSizeFromEnv = (process.env.REACT_APP_VIDEO_FILE_SIZE_MB || 7)
    const isFileSizeWithinLimits = file.size / 1024 / 1024 <=  fileSizeFromEnv

    if (!isFileSizeWithinLimits) {
      notification.error({
        message: t('Admin.DynamicInstructions.Upload.SizeError', {fileSize: fileSizeFromEnv}),
        duration: 3
      })

      return false
    }

    await convertFileToBase64(file).then(data => {
      const [, content] = data.split(',')
      onUpload(content)
    })

    return false
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  return (
    <Upload
      accept='.mp4'
      listType='picture'
      showUploadList={false}
      multiple={false}
      customRequest={dummyRequest}
      beforeUpload={beforeUpload}
      onChange={() => onChange(item)}
    >
      <CustomImg src={item.url_target} alt={item.seq} hasUrl={item.url_content} />
    </Upload>
  )
}
