import { useSWR } from '../hooks'

export const list = ({ fetcher, authentication }) => () =>  useSWR(
  authentication.user && `/remote_assistant/users/${authentication.user.id}/places`,
  (url) => fetcher.get(url).then(res => res),
  { revalidateOnFocus: false }
)

export const listForAlerts = ({ fetcher, authentication }) => () =>  useSWR(
  authentication.user && `/userauth/users/${authentication.user.id}/myplaces`,
  (url) => fetcher.get(url).then(res => res),
  { revalidateOnFocus: false }
)
