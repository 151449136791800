import { usePaginatedSWR, useSWR } from '../hooks'

export const list = ({ fetcher }) => (initialPage = 1, initialPageSize = 10) => usePaginatedSWR(
  'global/tools', null, initialPage, initialPageSize, (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1 } })
)

export const listActive = ({ fetcher })  => () => useSWR(
  'global/tools?active=1',(url) => fetcher.get(url).then(res => res.tools),
  { revalidateOnFocus: false }
)


export const get = ({ fetcher }) => (id) => useSWR(id && `global/tools/${id}`,
  (url) => fetcher.get(url).then(res => res && res.tool),
  { revalidateOnFocus: false }
)


export const update = ({ fetcher }) => async tool => {
  const data = {
    ...tool
  }
  await fetcher.put(`global/tools/${tool.id}`, data)
}

export const create = ({ fetcher }) => tool => fetcher.post('global/tools', tool)

export const remove = ({ fetcher }) => tool => fetcher.delete(`global/tools/${tool.id}`)
