import { usePaginatedSWR, useSWR } from '../hooks'

export const list = ({ fetcher }) => ({filter = {}, initialPage = 1, initialPageSize = 10}) => usePaginatedSWR(
  '/constructions', filter, initialPage, initialPageSize, (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, active: 1, ...filter } })
)

export const get = ({ fetcher }) => (id) => useSWR(id && `/constructions/${id}`,
  (url) => fetcher.get(url).then(res => res && res.construction),
  { revalidateOnFocus: false }
)

export const update = ({ fetcher }) => async construction => {
  const data = {
    ...construction
  }
  delete data.status
  await fetcher.put(`/constructions/${construction.id}`, data)
}

export const create = ({ fetcher }) => construction => fetcher.post('/constructions', construction)

export const remove = ({ fetcher }) => id => fetcher.delete(`/constructions/${id}`)

export const activities_upload = ({ fetcher }) => (construction_id, file_upload) => fetcher.post(`/constructions/${construction_id}/activities_upload`, file_upload).then(res => res.content)

export const files_create = ({ fetcher }) => (files, {construction_id}) => fetcher.post(`/constructions/${construction_id}/files`, files).then(res => res)

export const files_update = ({ fetcher }) => async (construction_id, file) => {
  await fetcher.put(`/constructions/${construction_id}/files/${file.id}`, file)
}

export const files_list = ({ fetcher }) => ({filter = {}, construction_id = null, initialPage = 1, initialPageSize = 10}) => usePaginatedSWR(
  `/constructions/${construction_id}/files`, filter, initialPage, initialPageSize, (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, active: 1, ...filter } })
)
export const files_remove = ({ fetcher }) => ({construction_id, file_id}) => fetcher.delete(`/constructions/${construction_id}/files/${file_id}`)

export const upload3dModel = ({ fetcher }) => (file, construction_id) =>{
  const postData = {
    "files": [{
      "filename": file.name,
      "extension": file.type
    }]
  }
  return fetcher.post(`/constructions/${construction_id}/file_upload`, postData)
  .then(res => {
    putS3(res.files[0].url, file)
    return res.files[0]
  })
}

const putS3 = async (uri, file) => {
  return await fetch(uri, {
    method: 'PUT',
    headers: {
      'Content-Type': '',
      'Accept': '*/*'
    },
    body: file.fileRaw
  }).then(res => res);
}

