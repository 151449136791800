import { useSWR } from '../hooks'

export const get = ({ fetcher }) => (processId, revisionId) => useSWR(
  processId && revisionId && `/processes/${processId}/revisions/${revisionId}/diagram`,
  (url) => fetcher.get(url).then(res => res.diagram),
  { revalidateOnFocus: false }
)

export const update = ({ fetcher }) => async (processId, revisionId, diagram) => {
  if (diagram && diagram.steps) {
    const data = {
      steps: diagram.steps.map(step => {
        const stepData = {...step}
        delete stepData.new

        return stepData
      })
    }

    await fetcher.put(`/processes/${processId}/revisions/${revisionId}/diagram`, data)

    return data
  }
}
