import { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Spin } from 'antd';

import { Indicator } from './components';
import { useApi } from './hooks';

Spin.setDefaultIndicator(<Indicator centered={false} />);

const LoginApp = lazy(() => import('./LoginApp'));
const SecuredApp = lazy(() => import('./SecuredApp'));
const ScheduleInvitation = lazy(() => import('./pages/ScheduleInvitation'));

const App = () => {
  const { isAuthenticated } = useApi();

  return (
    <Switch>
      {/* Unsecured routes */}
      <Route path="/invitation/:id" render={() => <ScheduleInvitation />} />

      <Route
        render={() => {
          if (isAuthenticated()) return <SecuredApp />;

          return <LoginApp />;
        }}
      />
    </Switch>
  );
};

export { App };
