import { usePaginatedSWR } from '../hooks'

const defaultFilter = {}

export const list = ({ fetcher }) => ({filter = defaultFilter, initialPage = 1, initialPageSize = 1000000} = {}) => usePaginatedSWR(
  '/processes/compliance_check', filter, initialPage, initialPageSize,
  (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, ...filter } })
)

export const getPDFLinks = ({ fetcher }) => ({selectedProcessId = null, period = null, initialPage = 1, filter = defaultFilter, initialPageSize = 1000000} = {}) => usePaginatedSWR(
  `/processes/compliance_check/${selectedProcessId}/`, filter, initialPage, initialPageSize,
  async (url, page, pageSize) => {
    if (selectedProcessId)
      return fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, period} })
    else
      return null
  }
)
