import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider } from 'styled-components/macro';

import AntdConfigProvider from './AntdConfigProvider';
import { App } from './App';
import { Indicator } from './components';
import { ErrorBoundary } from './ErrorBoundary';
import { useTheme, ApiProvider } from './hooks';

import { GlobalStyles } from './styles/global';

const Root = () => {
  const theme = useTheme();

  return (
    <Router>
      <GlobalStyles />

      <Suspense fallback={<Indicator />}>
        <ApiProvider>
          <ThemeProvider theme={theme}>
            <AntdConfigProvider>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </AntdConfigProvider>
          </ThemeProvider>
        </ApiProvider>
      </Suspense>
    </Router>
  );
};

export { Root };
