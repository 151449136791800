import React from 'react';

import Error from './pages/Error';

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, code: error.response && error.response.code };
  }

  // eslint-disable-next-line no-unused-vars, unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  clearError = () => {
    this.setState({ hasError: false });
  };

  render() {
    const { children } = this.props;
    const { hasError, code } = this.state as any;

    if (hasError) {
      return <Error code={code} clearError={this.clearError} />;
    }

    return children;
  }
}

const Component = ErrorBoundary as any;

export { Component as ErrorBoundary };
