import { usePaginatedSWR, useSWR } from '../hooks'

export const list = ({ fetcher }) => ({initialPage = 1, initialPageSize = 20, filter } = {}) => usePaginatedSWR(
   'catalogs', filter, initialPage, initialPageSize, (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, ...filter} })
 )

export const listDetails = ({ fetcher }) => ({catalogId, initialPage = 1, initialPageSize = 1000, filter } = {}) => usePaginatedSWR(
  `/catalogs/${catalogId}/dashboard`, filter, initialPage, initialPageSize, (url, page, pageSize) => filter && fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, ...filter} })
)

export const categoryList = ({ fetcher }) => (id) => useSWR(id && `/catalogs/${id}/categories`,
  (url) => fetcher.get(url, { params: { active: 1 }}).then(res => res),
  { revalidateOnFocus: false }
)

export const productList = ({ fetcher }) => (id) => useSWR(id && `/catalogs/${id}/products/?active=1`,
  (url) => fetcher.get(url).then(),
  { revalidateOnFocus: false }
)