import { useRef, useEffect, useState, useCallback } from 'react'

const getDimensions = (el) => {
  if (el) {
    return el.getBoundingClientRect()
  }

  return {}
}


export const useDimensions = () => {
  const ref = useRef()
  const [dimensions, setDimensions] = useState(getDimensions(ref.current))

  const handleResize = useCallback(
    () => {
      if (ref.current) {
        setDimensions(getDimensions(ref.current))
      }
    },
    [ref]
  )

  useEffect( () => {
    if (ref.current){
      handleResize()

      if (typeof ResizeObserver === 'function') {
        let resizeObserver = new ResizeObserver(handleResize)
        resizeObserver.observe(ref.current)

        return () => {
          resizeObserver.disconnect()
          resizeObserver = null
        }
      } else {
        window.addEventListener('resize', handleResize)

        return () => {
          window.removeEventListener('resize', handleResize)
        }
      }
    }

  }, [handleResize])

  return [ref, dimensions]
}

export default useDimensions
