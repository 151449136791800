import React from 'react'
import { Layout } from 'antd'
import styled from 'styled-components/macro'

const { Header, Footer: AntdFooter, Content } = Layout

const Container = styled(Layout)`
  height: 100%;
  background: unset;
`

const InnerContainer = styled(Container)`
  border: 1.2px solid #efefef;
`

const Page = ({ children, detail, ...props }) => (
  <Container {...props}>
    <InnerContainer>
      {children}
    </InnerContainer>
    {detail}
  </Container>
)

Page.SectionSubtitle = styled.h1`
  color: #064591;
  font-size: 24px;
  font-weight: light;
  padding-left: 20px;
`

Page.Title = styled(Header)`
  background-color: ${props => props.theme.primary.background};
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 23px;
  overflow-y: hidden;

  > a {
    height: 32px;
  }
`

Page.Body = styled(Content)`
  padding: 5px 20px;
  overflow-y: auto;
  flex: 1;
`

Page.FlexBody = styled(Content)`
  padding: 5px 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

Page.Footer = styled(AntdFooter)`
  background-color: #00579c;
  margin: 0px;
  padding: 5px;
`

Page.Actions = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  float: right;

  > a, > button, > span {
    display: block;
    margin: auto 2px;

    > button {
      display: block;
      box-sizing: border-box;
    }
  }
`

export { Page }
