/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'

export const useStableRouteMatch = (path) => {
  const _match = useRouteMatch(path)
  const location = useLocation()
  const [match, setMatch] = useState(_match)
  useEffect(() => {
    setMatch(_match)
  }, [location])
  return match
}
