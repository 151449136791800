import { useContext, createContext, useState } from 'react'

import * as api from '../api'
import { useAxios } from './useAxios'

const ApiContext = createContext()

const loadToken = () => {
  const storedValue = JSON.parse(localStorage.getItem('authentication'))
  return storedValue || { token: null, user: null, source: null }
}

const applyDependencies = (obj, deps) => {
  if (obj instanceof Function) {
    return obj(deps)
  } else {
    return Object.keys(obj).reduce((acc, key) => {
      acc[key] = applyDependencies(obj[key], deps)
      return acc
    }, {})
  }
}

export const useApi = () => {
  const [authentication, setAuthentication, fetcher] = useContext(ApiContext)

  const deps = {
    authentication,
    setAuthentication,
    fetcher
  }

  return {
    ...deps,
    ...applyDependencies(api, deps)
  }
}

export const ApiProvider = (props) => {
  const [authentication, setAuthentication] = useState(loadToken())
  const setAuthenticationAndStore = (token, user, source) => {
    localStorage.setItem('authentication', JSON.stringify({ token, user, source }))
    setAuthentication({ token, user, source })
  }
  const fetcher = useAxios(process.env.REACT_APP_API_ROOT, process.env.AGORA_IO_API_TIMEOUT, _ => authentication, async _ => setAuthenticationAndStore(null, null))

  return (
    <ApiContext.Provider value={[authentication, setAuthenticationAndStore, fetcher]}>
      {props.children}
    </ApiContext.Provider>
  )

}
