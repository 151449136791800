/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useMemo, useCallback, useRef } from 'react'

import { useClickOutside } from '../../hooks'
import { useState } from 'react'

const noop = () => {}

export const Draggable = ({ children, position, onMove, disabled, onDrop, onClick, mouse }) => {
  const ref = useRef()
  const [dragging, setDragging] = useState(false)
  const [initialPosition, setInitialPosition] = useState(position)
  const [offset, setOffset] = useState({ x: 0, y: 0 })

  const onCancel = useCallback(() => {
    if (dragging) {
      setDragging(false)
      onMove && onMove(initialPosition)
      onDrop && onDrop(initialPosition)
    }
  }, [dragging, initialPosition])
  useClickOutside(ref, onCancel)

  const newPosition = useMemo(_ => {
    if (mouse && offset && mouse.x && mouse.y) {
      return {
        x: mouse.x - offset.x,
        y: mouse.y - offset.y
      }
    } else {
      return initialPosition
    }
  }, [mouse, initialPosition, offset])

  const onStart = useCallback(async () => {
    await setInitialPosition(position)
    await setOffset({ x: mouse.x - position.x, y: mouse.y - position.y })
    setDragging(true)
  }, [position, mouse])

  const onStop = useCallback((evt) => {
    if (dragging) {
      if (Math.abs(initialPosition.x - newPosition.x) <= 3 && Math.abs(initialPosition.y - newPosition.y) <= 3) {
        onClick && onClick(evt)
      } else {
        onDrop && onDrop(newPosition)
      }
    }

    setDragging(false)
    setInitialPosition(newPosition)
    setOffset({ x: 0, y: 0 })
  }, [dragging, newPosition, initialPosition])

  useLayoutEffect(_ => {
    if (dragging) {
      onMove && onMove(newPosition)
    }
  }, [dragging, newPosition])

  React.Children.only(children)
  const element = React.cloneElement(children, {
    position,
    ref,
    dragging,
    onMouseDown: disabled ? noop : onStart,
    onMouseUp: disabled ? noop : onStop,
    onClick: e => e.stopPropagation()
  })
  return element
}
