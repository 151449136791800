import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin as AntdSpin } from 'antd'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

const Container = styled.div`
  ${props => props.centered && 'height: 100%;'}
  display: flex;
  ${props => props.withChildren  && `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    opacity: 0.5;
  `}
`

const Spin = styled(AntdSpin)`
  margin: auto;
  ${ props => props.theme && props.theme.primary && `color: ${props.theme.primary.color};` }
`

const defaultRenderIcon = () => <LoadingOutlined style={{ fontSize: 60 }} spin />

const Indicator = ({ centered = true, loading = true, children = undefined, renderIcon = defaultRenderIcon, ...props}) => {
  return (<>
    {children}
    { loading &&
      <Container centered={centered} {...props} withChildren={!!children}>
        <Spin indicator={renderIcon()}/>
      </Container>
    }
  </>)
}

Indicator.propTypes = {
  children: PropTypes.element
}

export { Indicator }
