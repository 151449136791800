import React from 'react'
import { Upload } from 'antd'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import UploadDefaultImagePt from '../../images/bt_insertPt.png'
import UploadDefaultImageEn from '../../images/bt_insertEn.png'
import UploadDefaultImageEs from '../../images/bt_insertEs.png'

const Container = styled.div`
  text-align: center;
  padding-top: 10px;

  img {
    width: 100px;
    height: 100px;
    display: inline-block;
    border-radius: 50%;
  }

  cursor: pointer;
`

const convertFileToBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = () => resolve(reader.result)
  reader.onerror = (error) => reject(error)
  reader.readAsDataURL(file)
})

export const UploadImage = (props) => {
  const { i18n } = useTranslation()
  const { src, alt, onUpload } = props

  const onChange = file => {
    convertFileToBase64(file).then(data => {
      const [, content] = data.split(',')
      onUpload({
        src: data,
        extension: file.name.slice((Math.max(0, file.name.lastIndexOf('.')) || Infinity) + 1),
        content
      })
    })

    return false
  }

  let defaultImage = UploadDefaultImageEn
  if (i18n.language.includes('pt')) {
    defaultImage = UploadDefaultImagePt
  } else if (i18n.language.includes('es')) {
    defaultImage = UploadDefaultImageEs
  }

  return (
    <Container>
      <Upload multiple={false}
          accept='.png, .jpeg, .jpg'
          showUploadList={false}
          beforeUpload={onChange}>
        <img src={src || defaultImage} alt={alt} />
      </Upload>
    </Container>
  )
}
