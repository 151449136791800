import { useMemo, useState } from 'react'

export const useStepConnector = (onConnect) => {
  const [from, setFrom] = useState()
  const [caption, setCaption] = useState()
  const [to, setTo] = useState()

  const connecting = useMemo(_ => !!from, [from])

  const resetConnection = () => {
    setFrom(null)
    setTo(null)
    setCaption(null)
  }

  const onConnectionStart = (id, caption) => () => {
    setFrom(id)
    setCaption(caption)
  }

  const onHoverDestination = (to) => () => connecting && setTo(to)
  const onLeaveDestination = () => setTo(null)

  const onConnectionEnd = () => {
    if (connecting) {
      onConnect(from, to, caption)
      resetConnection()
    }
  }

  return { onConnectionStart, onConnectionEnd, resetConnection,
    onHoverDestination, onLeaveDestination,
    connectionOrigin: from,
    connectionDestination: to,
    connectionCaption: caption,
    connecting }
}
