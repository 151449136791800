/* eslint-disable no-fallthrough */
import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Input, Image, Radio, Modal } from 'antd'
import ReactPlayer from 'react-player'
import { OBJModel } from 'react-3d-viewer'
//import { PDFViewer } from '@react-pdf/renderer'
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack'
import {useTranslation} from 'react-i18next'


const { TextArea } = Input

const RadioButton = styled(Radio)`
  display: block;
  height: 30px;
  line-height: 30px;
`

export const PreviewModal = ({ previewContent, handleCancelContent }) => {

  const { t } = useTranslation()
  // TODO: Waiting for resolutions
  // eslint-disable-next-line no-unused-vars
  const [onResolution, setOnResolution] = useState(700)
  const [onResolutionSelected, setOnResolutionSelected] = useState(1)

  const onResolutionChange = e => {
    // TODO: Change resolution according option
    // TODO: Waiting for resolutions
    // setOnResolutionSelected(e.target.value)
    if (e.target.value === 0) {
      // setOnResolution(500)
      setOnResolutionSelected(0)
    } else {
      // setOnResolution(700)
      setOnResolutionSelected(1)
    }
  }

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const renderPreview = (item) => {
    //TODO: Check resolution
    switch (item.type) {
      case 'I':
      case 'question-image':
        return <Image
          style={{
            minWidth: onResolution,
            transition: '0.5s'
          }}
          src={item.url}
        />
      case '3D':
        return <OBJModel
          src={item.url}
        />
      case 'question-3d':
        return <OBJModel
          url={item.url}
        />
      case 'P':
        return <div>
          <Document
            file={item.url}
            onLoadSuccess={onDocumentLoadSuccess}
          >
             {Array.apply(null, Array(numPages)).map((x, i)=>i+1).map(page => <Page pageNumber={page}><p>{t('PreviewModal.PDF.Page')} {page} {t('PreviewModal.PDF.of')} {numPages}</p></Page>)}

          </Document>

        </div>
      case 'question-pdf':
        // return <PDFViewer
        //   style={{
        //     minWidth: onResolution,
        //     transition: '0.5s'
        //   }}
        //   src={item.url}
        // />
      case 'V':
      case 'question-video':
        return <ReactPlayer
          width={onResolution}
          style={{ transition: '0.5s' }}
          controls={true}
          stopOnUnmount={previewContent.visible}
          playing={previewContent.visible}
          url={item.url}
        />
      case 'A':
      case 'question-audio':
        return <ReactPlayer
          width={onResolution}
          height={100}
          style={{ transition: '0.5s' }}
          controls={true}
          playing={previewContent.visible}
          url={item.url}
        />

      case 'alternative':
        return <>
        <TextArea
          rows={2} value={item.content_short}
          disabled={true}
          style={
            { minWidth: 400, minHeight:200 }}  />
            <br /><br />
        <TextArea
          rows={2} value={item.information}
          disabled={true}
          style={
            { minWidth: 400, minHeight: 200 }}  />
        </>
      default:
        return <TextArea
          style={
            { minWidth: onResolution - 200, minHeight: onResolution - 200 }}
          rows={4} value={onResolutionSelected === 0 ? item.content_short : item.content_long}
          disabled={true}
        />
    }
  }

  return (
    <Modal
      width={'min-content'}
      visible={previewContent.visible}
      title={previewContent.title}
      footer={null}
      style={{ transition: '0.5s' }}
      onCancel={handleCancelContent}
    >
      {/* <Radio.Group
        style={{ display: 'flex', marginBottom: 15 }}
        onChange={onResolutionChange}
        defaultValue={onResolutionSelected}
      >
        <RadioButton value={0}>HMT-1</RadioButton>
        <RadioButton value={1}>Tablet/Smartphone</RadioButton>
      </Radio.Group> */}

      {renderPreview(previewContent)}

    </Modal>
  )
}
