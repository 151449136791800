import React from 'react'
import styled from 'styled-components/macro'

import LanguageLink from './LanguageLink'
import EuaLogo from '../../images/eua.png'
import BrasilLogo from '../../images/brasil.png'
import SpainLogo from '../../images/espanha.png'

const Container = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`
export const LanguageSelector = ({ orientation = 'vertical', ...props }) => (
  <Container {...props}>
    <LanguageLink language='pt' image={BrasilLogo} orientation={orientation} />
    <LanguageLink language='en' image={EuaLogo} orientation={orientation} />
    <LanguageLink language='es' image={SpainLogo} orientation={orientation} />
  </Container>
)
