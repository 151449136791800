import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { notification } from 'antd'

export const useAxios = (baseURL, timeout = 30, getToken, invalidateToken) => {
  const { t } = useTranslation()

  const fetcher = axios.create({
    baseURL,
    timeout: timeout*1000,
    transformRequest: [(data, headers) => {
      const authentication = getToken()
      headers['Content-Type'] = 'application/json'
      if (authentication.token) {
        headers['Authorization'] = `Bearer ${authentication.token}`
      }
      return JSON.stringify(data)

    }]
  })

  fetcher.interceptors.response.use( response => response.data, error => {
    let parsedError = error
    try {
      const { response } = error
      if (response && response.status !== 200) {
        let result
        if (response.data && response.data.result) {
          result = response.data.result
        } else if (response.data && response.data.err && response.data.err.result) {
          result = response.data.err.result
        }
        if (result) {
          const { code, type } = result
          // "000-002": "Sessão Inválida",
          // "000-004": "Sessão expirada",
          // "000-006": "Token inválido ou expirado",
          if (type === 'E' && (code === '000-002' || code === '000-004' || code === '000-006')) {
            invalidateToken()
          }

          if (type === 'E') {
            notification.error({
              message: t(`ErrorCode.${code}`),
              duration: 3
            })
          }

          parsedError = {...error, response: result}

          return
        }
      }
      notification.error({
        message: t('ErrorCode.Unexpected'),
        duration: 3
      })
    } catch (anotherErr) {
      console.error(anotherErr)
    } finally {
      throw parsedError
    }
  })

  return fetcher
}
