import { usePaginatedSWR, useSWR } from '../hooks'

export const list = ({ fetcher }) => (initialPage = 1, initialPageSize = 10, processId, revisionId) => usePaginatedSWR(
  `processes/${processId}/revisions/${revisionId}/steps`, null, initialPage, initialPageSize, (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1 } })
)

export const get = ({ fetcher }) => (processId, revisionId, stepId) =>
  useSWR(stepId
    ? `processes/${processId}/revisions/${revisionId}/steps/${stepId}`
    : `processes/${processId}/revisions/${revisionId}/steps`,
    (url) => fetcher.get(url).then(res => res.step),
    { revalidateOnFocus: false }
  )

export const create =  ({ fetcher }) => async (processId, revisionId, stepId, values) => {
  const data = {
    ...values,
    id: stepId,
  }
  await fetcher.post(`/processes/${processId}/revisions/${revisionId}/steps`, data)
}

export const put =  ({ fetcher }) => async (processId, revisionId, stepId, values) => {
  await fetcher.put(`/processes/${processId}/revisions/${revisionId}/steps/${stepId}`, values)
}

export const removeStep = ({ fetcher }) => (processId, revisionId, stepId) =>
  fetcher.delete(`/processes/${processId}/revisions/${revisionId}/steps/${stepId}`)

export const createComplements = ({ fetcher }) => (values, processId, stepId) =>
  fetcher.post(`/processes/${processId}/steps/${stepId}/complements`, values).then(res => res.contents)

export const editComplements =  ({ fetcher }) => async (values, processId, stepId, complementId) => {
  await fetcher.put(`/processes/${processId}/steps/${stepId}/complements/${complementId}`, values)
}

export const removeComplements = ({ fetcher }) => (processId, stepId, complementId) => fetcher.delete(`/processes/${processId}/steps/${stepId}/complements/${complementId}`)

export const sortComplements =  ({ fetcher }) => async (processId, stepId, values) => {
  await fetcher.put(`/processes/${processId}/steps/${stepId}/position`, values)
}
