import { usePaginatedSWR, useSWR } from '../hooks'
import { v4 as uuidv4 } from 'uuid'

export const list = ({ fetcher }) => (initialPage = 1, initialPageSize = 10) => usePaginatedSWR(
  '/global/devices', null, initialPage, initialPageSize, (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, only_sg: 1 } })
)

export const get = ({ fetcher }) => (id) => useSWR(id && `/global/devices/${id}`,
  (url) => fetcher.get(url).then(res => res.response),
  { revalidateOnFocus: false }
)

export const update = ({ fetcher }) => async device => {
  const data = {
    ...device
  }
  delete data.profiles
  delete data.places
  await fetcher.put(`/global/devices/${device.id}`, data)
}

export const create = ({ fetcher }) => device => fetcher.post('/global/devices', {...device, devices_id: uuidv4()})

export const remove = ({ fetcher }) => device => fetcher.delete(`/global/devices/${device.id}`)
