import { useState, useEffect } from 'react'
import useOriginalSWR, { mutate } from 'swr'
import { useApi } from './useApi'

export const usePaginatedSWR = (keys, filter, initialPage, initialPageSize, fetcher, config) => {
  if ( !Array.isArray(keys) ) {
    keys = [keys]
  }

  const [ url, ...rest ] = keys

  const [page, setPage] = useState(initialPage)
  const [pageSize, setPageSize] = useState(initialPageSize)
  const [total, setTotal] = useState(0)
  const [cachedData, setCachedData] = useState()
  const response = useSWR([url, page, pageSize, filter, ...rest], fetcher, config)

  useEffect(_ => {
    if (response.data) {
      setCachedData(response.data)
      if (response.data.total_records !== undefined) {
        setTotal(response.data.total_records)

        if ( response.data.total_records <= ((page-1) * pageSize) ) {
          response.revalidate(null, false)
          setPage(Math.max(1, Math.ceil(response.data.total_records/pageSize)))
        }
      }
    }
  }, [response, total, page, pageSize])

  return {...response, data: response.data || cachedData, total, page: [page, setPage], pageSize: [pageSize, setPageSize] }
}

export const useSWR = (keys, fetcher, { shouldThrowError = true, ...config} = {} ) => {
  if ( keys && !Array.isArray(keys) ) {
    keys = [keys]
  }

  const { authentication } = useApi()
  const response = useOriginalSWR(keys && [...keys, authentication.token], fetcher, { shouldRetryOnError: false, ...config })
  if (shouldThrowError && response.error) {
    throw response.error
  }
  return { ...response, revalidate: response.mutate }
}
export const revalidate = mutate
