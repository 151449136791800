import { usePaginatedSWR, useSWR } from '../hooks'

export const list = ({ fetcher, authentication }) => ({filter = {}, initialPage = 1, initialPageSize = 20}) => usePaginatedSWR(
  '/processes/recertification', filter, initialPage, initialPageSize,
  (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, ...filter, users_requester: authentication.user.id } })
)

export const get = ({ fetcher }) => (id) => useSWR(id && `/remote_assistant/calls/${id}`,
  (url) => fetcher.get(url).then(res => res && res.call_detail),
  { revalidateOnFocus: false, suspense:true }
)

export const getUsers = ({ fetcher, authentication }) => ({filter}) => useSWR(`/userauth/users/list`,
  (url) => fetcher.get(url,  { params: { ...filter, users_requester: authentication.user.id} }).then(res => res),
  { revalidateOnFocus: false, suspense:true }
)

export const getProcesses = ({ fetcher }) => (process_type_id) => useSWR(`/processes?process_type_id=${process_type_id}`,
  (url) => fetcher.get(url).then(res => res.processes),
  { revalidateOnFocus: false }
)
