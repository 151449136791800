import React from 'react'
import { Typography, Avatar, List } from 'antd'
import { useTranslation } from 'react-i18next'
import { useApi } from '../../hooks'
import userDefault from '../../images/userDefault.png'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const { Text } = Typography

const TimesCircle = styled(FontAwesomeIcon).attrs(props => ({ icon: faTimesCircle }))`
  margin-left: 10px;
  font-size: 15px;
  color: red;
  cursor: pointer;
`

export const UserList = ({ userList, data, setUserList, ...props }) => {
  const { t } = useTranslation()
  const { authentication } = useApi()

  const userLoggedIn = authentication.user

  const handleUserStatus = (user) => {
    if (user.accepted_at) {
      return <Text type='success' strong>{t('RemoteAssistant.Settings.Schedules.Form.Accepted')}</Text>
    } else if (user.rejected_at) {
      return <Text type='danger' strong>{t('RemoteAssistant.Settings.Schedules.Form.Rejected')}</Text>
    } else {
      return <Text>{t('RemoteAssistant.Settings.Schedules.Form.Pending')}</Text>
    }
  }

  return (
    <List itemLayout='horizontal' {...props}>
      {userList.map((user) =>
        <List.Item key={user.id}>
          <List.Item.Meta
            avatar={(user.photo_url || user.url) ? <Avatar src={user.photo_url || user.url} /> : <Avatar src={userDefault} />}
            title={user.name + (user.state ? (' (' + (user.state) + ')') : '')}
            description={t('RemoteAssistant.Settings.Schedules.Form.' + user.role)}
            style={{ marginBottom: 15 }}
          />

          {(((data && data.created_by.id === user.users_id) || (!data && user.users_id === userLoggedIn.id))) ?
            <Text strong>{t('RemoteAssistant.Settings.Schedules.Form.Organizer')}</Text> :
            handleUserStatus(user)}

            {
              /* !(((data && data.created_by.id == user.users_id) || (!data && user.users_id === userLoggedIn.id))) && */
              <TimesCircle onClick={() => {
                setUserList(userList.filter(item => item.id !== user.id))
              }}/>
            }

        </List.Item>
      )}
    </List>
  )
}
