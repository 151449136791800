import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import logoImage from '../../images/logo_arvis.png'
import BackgroundImage from '../../images/background.jpg'
import { FormButton } from '../../components'

const Container = styled.div`
  height: 100%;
  display: flex;
  background: url('${BackgroundImage}') no-repeat center top;
  background-size: cover;
`

const Logo = styled.img`
  display: block;
  margin: 0 auto;
`

const CenteredContainer = styled.div`
  margin: auto;
`

const Title = styled.h1`
  display: block;
  text-align: center;
  margin-top: 45px;
  color: #313030;
`

const Subtitle = styled.h2`
  text-align: center;
  font-weight: lighter;
`

const Button = styled(FormButton)`
  display: block;
  margin: 50px auto;
`

const Error = ({ code, clearError }) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Container>
      <CenteredContainer>
        <Logo src={logoImage} alt='Arvis' />
        <Title>{t('Error.Message')}</Title>
        {code ?
          <Subtitle>{t(`ErrorCode.${code}`)}</Subtitle> :
          <Subtitle>{t(`ErrorCode.Unexpected`)}</Subtitle>
        }
        <Button onClick={() => {
          clearError()
          history.push('/dashboard')
        }} type='primary'>{t(`Error.ReturnToMenu`)}</Button>
      </CenteredContainer>
    </Container>
  )
}

export default Error
