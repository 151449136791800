export const useTheme = () => (
  {
    primary: {
      color: '#064591',
      background: '#efefef',
      iconColor: '#064591',
      accept: '#008000',
      reject: '#b40028'
    },
    secondary: {
      color: '#fff',
      background: '#3D8FC1'
    },
    tertiary: {
      color: '#66a7ce'
    },
    workflow: {
      ar: "#cfbaff",
      normal: "#EBF4FA",
      epi: "#F3E5AB",
      tool: "#7FFFD4",
      instruction: "#FBBBB9",
      multiple_choice: "#C3FDB8",
      only_choice: "#CFFFBA"
    }
  }
)//example useFetch('http://path.to.api/theme')
