/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { Row, Col, List, Avatar, Checkbox, Form, Modal, Select, Table, notification } from 'antd'
import { Page, FormButton } from '../../components'
import styled from 'styled-components/macro'
import { useApi } from '../../hooks'
import userDefault from '../../images/userDefault.png'
import { SearchOutlined, ClearOutlined, FormOutlined, DeleteOutlined, CheckSquareOutlined } from '@ant-design/icons'
import './distributionModal.css'

const SearchFormItem = styled(Form.Item)`
  margin: 5px 0;
  width: 100%;
`

const SearchForm = styled(Form)`
  margin: 15px;
`

export const DistributionModal = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [selection, setSelection] = useState()
  // eslint-disable-next-line no-unused-vars
  const [showValue, setShowValue] = useState()
  const { t } = useTranslation()
  const { Option } = Select

  const {
    title,
    visible,
    onModalClose,
    orderId,
    i18nPath
  } = props

  // eslint-disable-next-line no-unused-vars
  const onChange = (value, option) => {
    setShowValue(option.children)
    setSelection(value)
  }

  const id = orderId
  const { orders } = useApi()
  const [formGroup] = Form.useForm()
  const [formProcess] = Form.useForm()

  const [filterGroup, setFilterGroup] = useState({ group_id: null, team_id: null })
  const [filterProcess, setFilterProcess] = useState({ selectedProcess_id: null, flag_all_tasks: false, flag_not_distributed_tasks: false })

  const { data: groupAndProcesses } = orders.getGroupsAndProcesses({ order_id: id })

  const processes = groupAndProcesses && groupAndProcesses.processes
  const groups = groupAndProcesses && groupAndProcesses.groups


  let { group_id, team_id } = filterGroup
  group_id = group_id ? group_id : groups && groups.length > 0 ? groups[0].id : null

  const { selectedProcess_id } = filterProcess

  const process = selectedProcess_id ? processes.find((p) => p.processes_id == selectedProcess_id) :
    processes && processes.length > 0 ? processes[0] : null

  const { data: steps } = orders.getSteps({
    processes_id: process ? process.processes_id : null,
    revisions_id: process ? process.revisions_id : null
  });

  let [selectedGroup, setSelectedGroup] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [selectedTeam, setSelectedTeam] = useState({})

  if (groups && Object.keys(selectedGroup).length == 0) selectedGroup = groups[0].id

  const { data: teamsByGroup } = selectedGroup ? orders.getTeams({ group_id: selectedGroup }) :
    filterGroup.group ? orders.getTeams({ group_id: filterGroup.group }) : null

  const teams = teamsByGroup && teamsByGroup.length > 0 ? teamsByGroup[0].teams.filter((t) => t.active === 1) : null

  const [selectedTasks, setSelectedTasks] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)

  const { data: usersList } = orders.getUsers({ group_id, team_id })

  const { users } = usersList ? usersList : []

  let { data: distributions } = orders.getDistributions({ order_id: id })

  let distribution = distributions && process ? distributions.find((d) => d.processes.id == process.processes_id) : null

  const defaultDataSourceSteps = steps && steps.length > 0 && steps.map(step => {
    const user = distribution && distribution.steps_users.length > 0 ?
      distribution.steps_users.find((s) => s.steps.id == step.id) ?
        distribution.steps_users.find((s) => s.steps.id == step.id).users : null
      : null

    return {
      id: step.id,
      title: step.title,
      user: user && Object.keys(user).length > 0 ?
        <List
          dataSource={[user]}
          renderItem={item => (
            <List.Item key={item.id}>
              <List.Item.Meta
                avatar={
                  <Avatar src={item.photo_url ? item.photo_url : userDefault} />
                }
                title={item.name}
                description={item.email}
              />
            </List.Item>
          )}
        >
        </List> : null
    }
  })

  let [dataSourceSteps, setDataSourceSteps] = useState(defaultDataSourceSteps)

  dataSourceSteps = !dataSourceSteps ? defaultDataSourceSteps : dataSourceSteps

  const [submitting, setSubmitting] = useState(false)

  let columns = steps && steps.length > 0 && [
    {
      title: t(`${i18nPath}.Table.Id`),
      dataIndex: 'id',
      key: 'id',
      width: "20%"
    },
    {
      title: t(`${i18nPath}.Table.Title`),
      dataIndex: 'title',
      key: 'title',
      width: "40%"
    },
    {
      title: t(`${i18nPath}.Table.User`),
      dataIndex: 'user',
      key: 'user',
      width: "40%"
    },
  ]

  const onClickRow = (record, form) => {
    return {
      onClick: () => {
        let curSelected = selectedTasks;
        if (selectedTasks.find(s => s.id == record.id)) {
          curSelected = selectedTasks.filter(s => s.id != record.id)
        } else {
          curSelected.push(record)
        }
        setSelectedTasks(curSelected)
        form.submit()
        formGroup.submit()
      },
    };
  }
  const cleanSelection = () => {
    setSelectedTasks([])
  }

  const selectAllTasks = (e) => {
    if (e.target.checked) {
      setSelectedTasks(dataSourceSteps)
    } else {
      setSelectedTasks([])
    }
  }

  const selectTasksToDistribute = (e) => {
    if (e.target.checked) {
      setSelectedTasks(dataSourceSteps.filter((d) => d.user == null))
    } else {
      setSelectedTasks([])
    }
  }

  const setRowClassName = (record) => {
    return selectedTasks.find(s => s.id == record.id) ? 'clickRowStyl' : '';
  }

  const addOrRemoveAttributions = (type, form) => {
    if (!selectedUser && type == "add") {
      notification.info({
        message: t(`${i18nPath}.Alert.User`)
      })

    } else if (!selectedTasks || selectedTasks.length <= 0) {
      notification.info({
        message: t(`${i18nPath}.Alert.Task`)
      })
    } else {
      if (selectedTasks.filter((d) => d.user).length > 0 && type == "add") {
        notification.info({
          message: t(`${i18nPath}.Alert.SelectActivities`)
        })
      } else {
        let newDataSourceSteps = []
        if (type == "add") {
          newDataSourceSteps = dataSourceSteps.map((d) => {
            return selectedTasks.filter((s) => s.id === d.id).length > 0 ?
              {
                ...d, user: <List
                  dataSource={[selectedUser]}
                  renderItem={item => (
                    <List.Item key={item.id}>
                      <List.Item.Meta
                        avatar={
                          <Avatar src={item.photo_url ? item.photo_url : userDefault} />
                        }
                        title={item.name}
                        description={item.email}
                      />
                    </List.Item>
                  )}
                >
                </List>
              } : d
          })
        } else if (type == "rm") {
          newDataSourceSteps = dataSourceSteps.map((d) => {
            return selectedTasks.filter((s) => s.id === d.id).length > 0 ? { ...d, user: null } : d
          })
        }
        setDataSourceSteps(newDataSourceSteps)
      }
    }
  }

  const save = async values => {
    await setSubmitting(true)

    let newStepUsers = dataSourceSteps.map((s) => {
      return { ...s, users_id: s.user ? parseInt(s.user.props.dataSource[0].id) : null, steps_id: s.id }
    })

    newStepUsers = newStepUsers.filter((s) => {
      if (s.user) return s
    })

    Object.keys(newStepUsers).forEach(n =>
      delete newStepUsers[n].user &&
      delete newStepUsers[n].id &&
      delete newStepUsers[n].name)

    const newDistributions = distributions.map((d) => {
      if (distribution.processes.id == d.processes.id) {
        return { ...distribution, steps_users: newStepUsers, processes_id: d.processes.id }
      } else {
        const defaultSteps = d.steps_users.map((s) => {
          return { steps_id: s.steps.id, users_id: s.users.id }
        })
        return { ...d, processes_id: d.processes.id, steps_users: defaultSteps }
      }
    })

    Object.keys(newDistributions).forEach(n => delete newDistributions[n].processes &&
      delete newDistributions[n].orders_id &&
      delete newDistributions[n].created_at &&
      delete newDistributions[n].created_by &&
      delete newDistributions[n].updated_at &&
      delete newDistributions[n].updated_by
    )

    //Todo: Check if use 'result' is really necessary because in other pages we dont have notification sucess
    // eslint-disable-next-line no-unused-vars
    const { result } = await orders.saveDistributions({ order_id: id, distributions: newDistributions })

    await setSubmitting(false)
  }

  return (
    <Modal
      width={'max-content'}
      title={title}
      footer={null}
      visible={visible}
      onCancel={onModalClose}
    >

      <Page>
        <Page.Title>
          <Row>
            <Col>
              {t(`${i18nPath}.Title`)} {id}
            </Col>
          </Row>
        </Page.Title>
        <Page.Body>
          <Row>
            <Col span={8}>
              <SearchForm form={formGroup} name='formGroup' layout='inline' onFinish={setFilterGroup}>
                <SearchFormItem name='group_id'>
                  {groups &&
                    <Select
                      placeholder={t(`${i18nPath}.Group`)}
                      showSearch
                      style={{
                        width: 250,
                      }}
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                      (option.children &&
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                      }
                      defaultValue={groups && groups[0] && groups[0].id}
                      onChange={(v) => { setSelectedGroup(v) }}
                    >
                      {groups && groups.map((group) =>
                        <Option key={group.id} value={group.id}>{group.name}</Option>
                      )}
                    </Select>
                  }
                </SearchFormItem>
                {teams && teams.length > 0 &&
                  <SearchFormItem name='team_id'>
                    <Select
                      placeholder={t(`${i18nPath}.Team`)}
                      showSearch
                      style={{
                        width: 250,
                      }}
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                      (option.children &&
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                      }
                      onChange={(v) => { setSelectedTeam(v) }}
                    >
                      {teams.map((team) =>
                        <Option key={team.id} value={team.id}>{team.name}</Option>
                      )}
                    </Select>
                  </SearchFormItem>
                }

                <SearchFormItem>
                  <FormButton style={{ marginLeft: 5, marginRight: 5 }} onClick={() => {
                    setSelectedUser(null)
                    formGroup.resetFields()
                    setSelectedGroup(group_id)
                    formGroup.submit()
                  }}>
                    {t(`${i18nPath}.Clear`)} <ClearOutlined />
                  </FormButton>
                  <FormButton style={{ marginLeft: 5, marginRight: 5 }} type='primary' onClick={() => {
                    setSelectedUser(null)
                    formGroup.submit()
                  }}>
                    {t(`${i18nPath}.Search`)} <SearchOutlined />
                  </FormButton>
                </SearchFormItem>
              </SearchForm>
              {users && users.length > 0 &&
                <List
                  dataSource={users}
                  renderItem={item => (
                    <List.Item style={selectedUser && selectedUser.id == item.id ? { backgroundColor: '#f0f0f0', padding: 10 } : { padding: 10 }}
                      key={item.id} onClick={() => {
                        selectedUser == item ? setSelectedUser(null) : setSelectedUser(item)
                      }
                      }
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar src={item.photo_url ? item.photo_url : userDefault} />
                        }
                        title={item.name}
                        description={item.email}
                      />
                    </List.Item>
                  )}
                >
                </List>}
            </Col>
            <Col span={16}>
              <SearchForm form={formProcess} name='formProcess' layout='inline' onFinish={setFilterGroup}>
                <SearchFormItem name='process' >
                  {processes && <Select
                    showSearch
                    style={{
                      width: 250,
                    }}
                    defaultValue={processes && processes[0].processes_id}
                    optionFilterProp='children'
                    onChange={(value, e) => {
                      setFilterProcess({ selectedProcess_id: value, flag_all_tasks: false, flag_not_distributed_tasks: false })
                      setDataSourceSteps(null)
                      cleanSelection()
                    }}
                    filterOption={(input, option) =>
                    (option.children &&
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                    }
                  >
                    {processes && processes.map((process) =>
                      <Option key={process.processes_id} value={process.processes_id}>{process.name}</Option>
                    )}
                  </Select>
                  }
                </SearchFormItem>
                <SearchFormItem
                  name='flag_all_tasks'
                  valuePropName='checked'
                  initialValue={filterProcess.flag_all_tasks}>
                  <Checkbox disabled={dataSourceSteps ? false : true} onClick={(e) => { selectAllTasks(e) }}>{t(`${i18nPath}.AllTasks`)}</Checkbox>
                </SearchFormItem>
                <SearchFormItem
                  name='flag_not_distributed_tasks'
                  valuePropName='checked'
                  initialValue={filterProcess.flag_not_distributed_tasks}>
                  <Checkbox disabled={dataSourceSteps ? false : true} onClick={(e) => { selectTasksToDistribute(e) }}>{t(`${i18nPath}.NotDistributedTasks`)}</Checkbox>
                </SearchFormItem>
              </SearchForm>
              {
                steps && columns &&
                <Table
                  dataSource={dataSourceSteps}
                  columns={columns}
                  loading={!dataSourceSteps || !columns}
                  rowKey={record => record.id}
                  style={{ marginLeft: 5, marginRight: 5 }}

                  onRow={(record) => onClickRow(record, formProcess)}
                  rowClassName={setRowClassName}

                />
              }
              <SearchFormItem>
                <FormButton disabled={submitting} style={{ marginLeft: 5, marginRight: 5 }} onClick={() => {
                  addOrRemoveAttributions("rm", formGroup)
                  cleanSelection()
                }}>
                  {t(`${i18nPath}.Remove`)} <DeleteOutlined />
                </FormButton>
                <FormButton disabled={submitting} style={{ marginLeft: 5, marginRight: 5 }} type='primary' onClick={() => {
                  addOrRemoveAttributions("add", formGroup)
                  cleanSelection()
                }} >
                  {t(`${i18nPath}.Distribute`)} <CheckSquareOutlined />
                </FormButton>
              </SearchFormItem>
              <SearchFormItem>
                <FormButton disabled={submitting} style={{ marginLeft: 5, marginRight: 5 }} type='primary' onClick={() => {
                  save()
                  cleanSelection()
                }} >
                  {t(`${i18nPath}.Save`)} <FormOutlined />
                </FormButton>
              </SearchFormItem>
            </Col>
          </Row>
        </Page.Body>
      </Page>
    </Modal>

  );
}


