import { useSWR, usePaginatedSWR } from '../hooks'

export const list = ({ fetcher }) => (placeId) => useSWR(placeId && `global/alerts?places_id=${placeId}`,
  (url) => fetcher.get(url).then(res => res && res.alerts),
  { revalidateOnFocus: false }
)

export const get = ({ fetcher }) => (id) => useSWR(id && `global/alerts/${id}`,
  (url) => fetcher.get(url).then(res => res && res.alert && res.alert),
  { revalidateOnFocus: false }
)

export const create = ({ fetcher }) => async alert => {
  await fetcher.post('global/alerts', alert)
}

export const update = ({ fetcher }) => async alert => {
  const data = {
    ...alert
  }

  delete data.id
  delete data.created_by
  delete data.created_at
  delete data.updated_by
  delete data.updated_at
  await fetcher.put(`global/alerts/${alert.id}`, data)
}

export const remove = ({ fetcher }) => async id => fetcher.delete(`global/alerts/${id}`)
/***********************************************************************/
// //API: 602 - Histórico de Disparos de Alerta(GET): https://api.dev.arvis.digital/global/alerts/triggers
export const getTrigger = ({ fetcher }) => (id) => useSWR(id && `global/alerts/triggers/${id}`,
  (url) => fetcher.get(url).then(res => {
      return res && res.triggers && res.triggers}),
  { revalidateOnFocus: false }
)

export const getTriggers = ({ fetcher }) => ({filter = {}, initialPage = 1, initialPageSize = 10}) => usePaginatedSWR(
  'global/alerts/triggers', filter, initialPage, initialPageSize,
  (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, ...filter } })
)
/**********************************************************************/


