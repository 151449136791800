import { render } from 'react-dom';

import './i18n';
import 'antd/dist/antd.css';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { Root } from './Root';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: 'https://26ef6906636b4d6e9c1757b93056d294@o975095.ingest.sentry.io/5952271',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
