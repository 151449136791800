import { usePaginatedSWR, useSWR } from '../hooks'

const defaultFilter = {}

export const list = ({ fetcher, authentication }) => ({filter = defaultFilter, initialPage = 1, initialPageSize = 10} = {}) => usePaginatedSWR(
  '/dynamic_instructions', filter, initialPage, initialPageSize,
  (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, users_id:authentication.user.id, ...filter } })
)

export const get = ({ fetcher }) => (id) => useSWR(id && `/dynamic_instructions/${id}`,
  (url) => fetcher.get(url).then(res => res.dynamic_instruction),
  { revalidateOnFocus: false }
)

export const update = ({ fetcher }) => (dynamicInstruction) => fetcher.put(`/dynamic_instructions/${dynamicInstruction.id}`, dynamicInstruction)

export const create = ({ fetcher }) => dynamicInstruction => fetcher.post('/dynamic_instructions', dynamicInstruction)

export const remove = ({ fetcher }) => dynamicInstruction => fetcher.delete(`/dynamic_instructions/${dynamicInstruction.id}`)

export const uploadFile = ({ fetcher }) => (id, fileId, file) => fetcher.put(`/dynamic_instructions/${id}/target/${fileId}`, file)

export const deleteFile = ({ fetcher }) => (id, fileId) => fetcher.delete(`/dynamic_instructions/${id}/target/${fileId}`)
