import { usePaginatedSWR, useSWR } from '../hooks'

const defaultFilter = {}

export const list = ({ fetcher }) => ({filter = defaultFilter, initialPage = 1, initialPageSize = 10 } = {}) =>
  usePaginatedSWR(`/processes/types/4/with_revision`, filter, initialPage, initialPageSize,
  (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, ...filter } })
)

export const get = ({ fetcher }) => (processId, revisionId) => useSWR(`processes/${processId}/revisions/${revisionId}/steps`,
  (url) => fetcher.get(url).then(res => res.steps),
  { revalidateOnFocus: false }
)


