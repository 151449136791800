import { usePaginatedSWR, useSWR } from '../hooks'

export const list = ({ fetcher }) => (initialPage = 1, initialPageSize = 10) => usePaginatedSWR(
  'global/pictograms', null, initialPage, initialPageSize, (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1 } })
)

export const listActive =  ({ fetcher }) => () => useSWR(
  'global/pictograms?active=1',(url) => fetcher.get(url).then(res => res.pictograms),
  { revalidateOnFocus: false }
)

export const get = ({ fetcher }) => (id) => useSWR(id && `global/pictograms/${id}`,
  (url) => fetcher.get(url).then(res => res.pictogram),
  { revalidateOnFocus: false }
)

export const update = ({ fetcher }) => async pictogram => {
  const data = {
    ...pictogram
  }

  await fetcher.put(`global/pictograms/${pictogram.id}`, data)
}

export const create = ({ fetcher }) => pictogram => fetcher.post('global/pictograms', pictogram)

export const remove = ({ fetcher }) => pictogram => fetcher.delete(`global/pictograms/${pictogram.id}`)
