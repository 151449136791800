import React from 'react'
import { List } from 'antd'
import PropTypes from 'prop-types'

const noop = res => res

const CrudList = ({ dataSource: {data, page: [page, setPage], pageSize: [pageSize, setPageSize], total, isValidating}, mapper = noop, children }) => (
  <List loading={!data || isValidating}
    dataSource={ data && mapper(data) }
    renderItem={ children }
    pagination={total > 0 && {position: 'both', current: page, pageSize, total, onChange: setPage, style: {margin: '10px'}, showSizeChanger: false}} />
)

CrudList.propTypes = {
  children: PropTypes.func.isRequired
}

export { CrudList }
