import React from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

export const useConfirmationDialog = (title, content, onOk) => {
  const { t } = useTranslation()
  return {
    open: (...args) => {
      Modal.confirm({
        title,
        icon: <ExclamationCircleOutlined />,
        content,
        okText: t('Forms.Yes'),
        cancelText: t('Forms.No'),
        onOk: () => onOk(...args)
      })
    }
  }
}
