import { useState } from 'react'
import { List, Space, Modal} from 'antd'
import ReactPlayer from 'react-player'

import { UploadVideo } from '../../components'
import { VideoItem } from './VideoItem'

export const VideoList = (props) => {
  const { data, onUpload, handleChange, confirmDelete } = props
  const [preview, setPreview] = useState({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
  })

  const handleCancel = () => {
    setPreview({ previewVisible: false })
  }

  const { previewVisible, previewImage, previewTitle } = preview

  return (
    <>
      <List
        grid={{ xxl: 5 }}
        dataSource={data}
        renderItem={item => (
          <List.Item>
            <Space>
              <UploadVideo item={item} onUpload={onUpload} onChange={handleChange} />
              <VideoItem item={item} preview={setPreview} onUpload={onUpload} confirmDelete={confirmDelete}/>
            </Space>
          </List.Item>
        )}
      />

      <Modal
        width={'min-content'}
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <ReactPlayer
          controls={true}
          playing={true}
          url={previewImage} />
      </Modal>
    </>
  )
}
