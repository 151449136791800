import axios from 'axios'

import { getClient } from './useClient'
import { useEntryUrl } from './useEntryUrl'
import { suspend } from './helpers'

const { client, whitelabel } = getClient()

const fetchAuthConfig = suspend(axios.get(`${process.env.REACT_APP_API_ROOT}/login/credentials`, { params: { whitelabel }}).then(res => res.data))

export const useAuthConfig = () => {
  const config = fetchAuthConfig.read()
  const entryUrl = useEntryUrl()

  if (config.type === 'oauth') {
    const webAuthCallbackUrl = encodeURIComponent(`${entryUrl}/login_web`)
    const unityAuthCallbackUrl = encodeURIComponent(`${entryUrl}/login_mobile`)

    const baseRedirectUrl = `${config.authUrl}?client_id=${config.clientId}&response_type=code&scope=${config.scopes.join('+')}${config.acr_values ? `&acr_values=${config.acr_values}` : ''}`
    config.redirectUrl = `${baseRedirectUrl}&redirect_uri=${webAuthCallbackUrl}`
    config.unityRedirectUrl = `${baseRedirectUrl}&redirect_uri=${unityAuthCallbackUrl}`
  }

  console.log('config', config);

  return {
    arvisTokenUrl: `/login/${client}/session`,
    ...config
  }
}
