import {useSWR} from "../hooks";

export const create = ({ fetcher }) => async (teams, groupId) => {
  await fetcher.post(`remote_assistant/groups/${groupId}/teams`, {...teams })
}

export const remove = ({ fetcher }) => async (groupId, teamId) =>
  await fetcher.delete(`remote_assistant/groups/${groupId}/teams/${teamId}`
  )

export const update = ({ fetcher }) => async (groupId, teamId, values) => {
  await fetcher.put(`remote_assistant/groups/${groupId}/teams/${teamId}`, values)
}

export const get = ({ fetcher }) => (groupId, teamId) => useSWR(
  groupId && teamId && `/remote_assistant/groups/${groupId}/teams/${teamId}`,
  (url) => fetcher.get(url).then(res => res),
  { revalidateOnFocus: false }
)

export const updateContacts = ({ fetcher }) => async (groupId, teamId, selectedContacts) => {
  const data = {
    teams_users: selectedContacts.map(user => (
      {
        id: user.id
      }
    ))
  }
  await fetcher.post(`remote_assistant/groups/${groupId}/teams/${teamId}/users`, data)
}

export const getContacts = ({ fetcher }) => (groupId) =>
  useSWR(groupId && `/remote_assistant/groups/${groupId}/contacts?assigned=1`,
    (url) => fetcher.get(url).then(response => {
      const responseContacts = response.group_users[0]
      const elements = responseContacts.map(item => (
        {
          id: item.users.id,
          name: item.users.name,
          email: item.users.email
        }
      ))

      const selectedContactIds = responseContacts.filter(item => item.id && item.id !== '')
        .map(item => item.users.id)

      return { elements, selectedContactIds }
    })
  )
