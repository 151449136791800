import { usePaginatedSWR, useSWR } from '../hooks'
import { v4 as uuidv4 } from 'uuid'

export const list = ({ fetcher }) => ({initialPage = 1, initialPageSize = 10, filter } = {}) => usePaginatedSWR(
  'remote_assistant/groups', filter, initialPage, initialPageSize, (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, ...filter} })
)

export const getActiveGroups = ({ fetcher }) => () => useSWR(`remote_assistant/groups?active=1`,
  (url) => fetcher.get(url).then(res => res && res.groups),
  { revalidateOnFocus: false }
)


export const get = ({ fetcher }) => (id) => useSWR(id && `remote_assistant/groups?group_id=${id}`,
  (url) => fetcher.get(url).then(res => res && res.groups && res.groups.length > 0 && res.groups[0]),
  { revalidateOnFocus: false }
)

export const update = ({ fetcher }) => async group => {
  const data = {
    ...group
  }
  delete data.profiles
  delete data.places
  delete data.created_by
  delete data.created_at
  delete data.updated_by
  delete data.updated_at
  await fetcher.put(`remote_assistant/groups/${group.id}`, data)
}

export const create = ({ fetcher }) => async group => {
  group.id = uuidv4()
  await fetcher.post('remote_assistant/groups', group)
}

export const remove = ({ fetcher }) => async id => fetcher.delete(`remote_assistant/groups/${id}`)

export const getPlaces = ({ fetcher }) => (groupId) => useSWR(groupId && `remote_assistant/groups/${groupId}/places`,
  (url) => fetcher.get(url).then(response => {

    const responsePlaces = response.group_places[0]
    const elements = responsePlaces.map( item => (
        {
          associationId: item.id,
          name: item.places.name,
          id: item.places.id
        }
      ))
    const selectedPlaceIds = responsePlaces.filter( item => item.id && item.id !== '').map( item => item.places.id )

    return {elements, selectedPlaceIds}
  })
)

 export const linkToPlaces = ({ fetcher }) => async (group, selectedPlaces) => {
  const data = {
    group_places: selectedPlaces.map( place => ({
      id: place.associationId || uuidv4(),
      places_id: place.id
    }))
  }

  await fetcher.post(`remote_assistant/groups/${group.id}/places`, data)
}

export const linkToContacts = ({ fetcher }) => async (group, selectedContacts) => {
  const data = {
    group_users: selectedContacts.map( contact => ({
      id: contact.associationId || uuidv4(),
      users_id: contact.id
    }))
  }

  await fetcher.post(`remote_assistant/groups/${group.id}/contacts`, data)
}

export const getContacts = ({ fetcher }) => (groupId) => useSWR(groupId && `/remote_assistant/groups/${groupId}/contacts`,
  (url) => fetcher.get(url).then(response => {

    const responseContacts = response.group_users[0]
    const elements = responseContacts.map( item => (
        {
          associationId: item.id,
          name: item.users.name,
          email: item.users.email,
          cargo: item.users.cargo,
          places: item.users.places,
          id: item.users.id
        }
      ))
    const selectedContactIds = responseContacts.filter( item => item.id && item.id !== '').map( item => item.users.id )
    const availablePlaces = []

    const addedPlaceIds = []
    responseContacts.forEach( item => {
      item.users.places.forEach( place => {
        if( addedPlaceIds.includes( place.id ) ) {
          return
        }

        addedPlaceIds.push( place.id )
        availablePlaces.push( place )
      })
    })

    return {elements, selectedContactIds, availablePlaces}
  })
)


