import { usePaginatedSWR, useSWR } from '../hooks'
import { v4 as uuidv4 } from 'uuid'

export const list = ({ fetcher, authentication }) => ({ filter = {}, initialPage = 1, initialPageSize = 10 }) => usePaginatedSWR(
  `/remote_assistant/users/${authentication.user.id}/schedule`, filter, initialPage, initialPageSize, (url, page, pageSize) => {
    const filterCopy = {
      ...filter
    }
    if (filterCopy.date_from) {
      filterCopy.date_from = filterCopy.date_from.format('YYYY-MM-DD 00:00:01')
    }
    if (filterCopy.date_to) {
      filterCopy.date_to = filterCopy.date_to.format('YYYY-MM-DD 23:59:59')
    }
    filterCopy.flag_sched_confirmed = filterCopy.flag_sched_confirmed ? 1 : 0

    return fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, ...filterCopy } })
  }
)

export const get = ({ fetcher, authentication }) => (id, suspense = false) => useSWR(id && `/remote_assistant/users/${authentication.user.id}/schedule/${id}`,
  (url) => fetcher.get(url).then(res => res.schedules),
  { revalidateOnFocus: false, suspense }
)

export const update = ({ fetcher, authentication }) => async schedule => {
  await fetcher.put(`/remote_assistant/users/${authentication.user.id}/schedule/${schedule.id}`, schedule)
}

export const create = ({ fetcher, authentication }) => schedule => fetcher.post(`/remote_assistant/users/${authentication.user.id}/schedule`, { ...schedule, id: uuidv4() })

export const remove = ({ fetcher, authentication }) => schedule => fetcher.delete(`/remote_assistant/users/${authentication.user.id}/schedule/${schedule.id}`)

export const accept = ({ fetcher }) => async (id) => {
  await fetcher.put(`remote_assistant/schedule/${id}/accept`)
}

export const reject = ({ fetcher }) => async (id) => {
  await fetcher.put(`remote_assistant/schedule/${id}/reject`)
}

export const getProcesses = ({ fetcher }) => (process_type_id) => useSWR(`/processes?process_type_id=${process_type_id}`,
  (url) => fetcher.get(url).then(res => res.processes),
  { revalidateOnFocus: false }
)

export const getUsers = ({ fetcher }) => (id) => useSWR(id && `/userauth/users?id=${id}`,
  (url) => fetcher.get(url).then(res => res && res.users && res.users.length > 0 && res.users[0]),
  { revalidateOnFocus: false }
)