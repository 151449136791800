import React from 'react'
import styled from 'styled-components/macro'
import { Row, Col } from 'antd'

const Container = styled(Row)`
  margin: 0 20px;

  h1 {
    font-size: 16px;
    margin: 0;
    color: white;
  }

  p {
    margin: 5px 0;
    color: white;
  }
`

export const Alert = ({title, description, icon, ...props}) => (
  <Container {...props}>
    { icon &&
      <Col span={1}>
        {icon}
      </Col>
    }
    <Col span={icon ? 23 : 24}>
      <h1>{title}</h1>
      {description && <p>{description}</p>}
    </Col>
  </Container>
)
