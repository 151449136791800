/* eslint-disable array-callback-return */
import { usePaginatedSWR, useSWR } from '../hooks'

const defaultFilter = {}

export const list = ({ fetcher }) => ({filter = defaultFilter, initialPage = 1, initialPageSize = 10} = {}) => usePaginatedSWR(
  '/processes', filter, initialPage, initialPageSize,
  (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, ...filter } })
)

export const logList = ({ fetcher }) => ({filter = {} , initialPage = 1, initialPageSize = 10} = {}) => usePaginatedSWR(
  '/processes/logs', filter, initialPage, initialPageSize,
  (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, ...filter } })
)

export const report  = ({ fetcher }) => ({ filter }) => useSWR([`/processes/logs`, filter],
  (url) => fetcher.get(url, { params: {...filter }}).then(res => res.logs),
  { revalidateOnFocus: false, suspense:true }
)

export const logListID = ({ fetcher }) => (id, {filter = defaultFilter , initialPage = 1, initialPageSize = 10} = {}) => usePaginatedSWR(
  id && `/processes/logs/${id}/log`, filter, initialPage, initialPageSize,
  (url, page, pageSize) => {

    let results = fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, ...filter } })
    .then( r =>
      {
        //console.log(r.log.logs_steps);
        let steps_concatenates = []
        r && r.log && r.log.logs_steps.map(step => {
          if(step.steps_type === 'epi' || step.steps_type === 'tool'){
            let stepSelected = steps_concatenates.filter(s => s.steps_id === step.steps_id)

            step.content.compliance_check_not_ok = step.compliance_check_not_ok

            if(stepSelected.length === 0){
              step.content = [step.content]
              steps_concatenates.push(step)

            }else{
              console.log('step.evidences:: ', step.evidences)

              let contentActual = stepSelected[0].content
              let contentStep = step.content

              let evidencesActual = stepSelected[0].evidences
              let evidencesStep = step.evidences

              step.content = contentActual
              step.content.push(contentStep)

              step.evidences = evidencesActual
              evidencesStep.map(i=>{
                step.evidences.push(i)
              })
            }
          }else{
            steps_concatenates.push(step)
          }
        })

        if (r && r.log) r.log.logs_steps = steps_concatenates
        return r
      }
    )

    return results
  }
)

export const createLogComments = ({ fetcher }) => (data, log_id) => fetcher.post(`/processes/logs/${log_id}/not_sequential`, data)
export const updateLogComments = ({ fetcher }) => async (data, log_id) => await fetcher.put(`/processes/logs/${log_id}/not_sequential/${data.id}`, data)
export const excludeActivityFromReport = ({ fetcher }) => async (data, log_id) => await fetcher.put(`/processes/logs/${log_id}/not_sequential/activity/${data.id}`, {exclude_from_final_report: data.exclude_from_final_report})

export const logListIDNonSeq = ({ fetcher }) => (id, {filter = defaultFilter , initialPage = 1, initialPageSize = 10} = {}) => usePaginatedSWR(
  id && `/processes/logs/${id}/not_sequential`, filter, initialPage, initialPageSize,
  (url, page, pageSize) => {

    let results = fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, ...filter } })
    .then( r =>
      {
        r && r.log && r.log.activities && r.log.activities.map((activity, idx) => {
          let steps_concatenates = []
          activity.logs_steps.map(step => {

            if(step.steps_type === 'epi' || step.steps_type === 'tool'){
              let stepSelected = steps_concatenates.filter(s => s.steps_id === step.steps_id)
              if(stepSelected.length === 0){
                step.content = [step.content]
                steps_concatenates.push(step)
              }else{

                let contentActual = stepSelected[0].content
                let contentStep = step.content

                let evidencesActual = stepSelected[0].evidences
                let evidencesStep = step.evidences

                step.content = contentActual
                step.content.push(contentStep)

                step.evidences = evidencesActual
                evidencesStep.map(i=>{
                  step.evidences.push(i)
                })
              }
            }else{
              steps_concatenates.push(step)
            }
          })

          if (r && r.log) r.log.activities[idx].logs_steps = steps_concatenates
        })
        return r
      })
      return results
  })

export const logListEvaluation = ({ fetcher }) => (id, revisionId, logId) => useSWR(id && `/processes/${id}/revisions/${revisionId}/logs/${logId}/evaluation`,
  (url) => fetcher.get(url).then(r => r)
)

export const create = ({ fetcher }) => process => fetcher.post('/processes', process)

export const remove = ({ fetcher }) => process => fetcher.delete(`/processes/${process.id}`)

export const duplicate = ({ fetcher }) => id => fetcher.post(`/processes/${id}/duplicate`)

export const get = ({ fetcher }) => (id, revisionId) => useSWR(id && `/processes/${id}/revisions/${revisionId}`,
  (url) => fetcher.get(url).then(res => res.process),
  { revalidateOnFocus: false }
)

export const update = ({ fetcher }) => async (process, id, revisionId) => {
  await fetcher.put(`/processes/${id}/revisions/${revisionId}`, process)
}
// export const getAssets = ({ fetcher }) => (id) => useSWR(id && `/global/assets`,
//   (url) => fetcher.get(url).then(res => res.processes),
//   { revalidateOnFocus: false }
// )

export const removeRevision = ({ fetcher }) => (id, revisionId) => fetcher.delete(`/processes/${id}/revisions/${revisionId}`)

export const duplicateRevision = ({ fetcher }) => (id, revisionId) => fetcher.post(`/processes/${id}/revisions/${revisionId}/duplicate`)

export const uploadXlsx = ({ fetcher }) => (file, processId, revisionId) =>
  fetcher.post(`/processes/${processId}/revisions/${revisionId}/upload`, file).then(res => res.content)
