import React from 'react'
import {Button} from 'antd'
import styled from 'styled-components/macro'

const ButtonContainer = styled(Button) `
  font-size: 14px;
  background-color: #064591;
  color: #FFF;
  border-color: #064591;
`
const FormButton = ({children, ...props}) => {
  return (
  <ButtonContainer {...props}>
    {children}
  </ButtonContainer> );
}

export  { FormButton };
