import React from 'react'
import { List, Typography } from 'antd'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'

const DragHandle = SortableHandle((props) => (
	<Typography.Text style={{ cursor: 'pointer' }} ellipsis	>
		{props.value.title}
	</Typography.Text>

))

export const SortableItem = SortableElement((props) => (
	<List.Item
		{...props}
	>
		<DragHandle value={props.value} disabled={props.disabled} />
	</List.Item>
))
