import React from 'react'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'

const { Option } = Select

const StatusDropdown = ({showEmptyOption, ...props}) => {

  const { t } = useTranslation()

  return (
    <Select
      {...props}
      style={{
        width: 100,
      }}
    >
      { showEmptyOption && <Option value={null}></Option> }
      <Option value={1}>{t('Admin.Groups.RowStatusValueActive')}</Option>
      <Option value={0}>{t('Admin.Groups.RowStatusValueInactive')}</Option>
    </Select>
  )
}

export {StatusDropdown}
