import { v4 as uuidv4 } from 'uuid'

import { usePaginatedSWR, useSWR } from '../hooks'

export const report  = ({ fetcher }) => ({ filter }) => useSWR([`/processes/evaluations`, filter],
  (url) => fetcher.get(url, { params: {...filter }}).then(res => res),
  { revalidateOnFocus: false, suspense:true }
)

export const list = ({ fetcher, authentication }) => ({filter = {}, initialPage = 1, initialPageSize = 20}) => usePaginatedSWR(
  '/processes/evaluations', filter, initialPage, initialPageSize,
  (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, ...filter, users_requester: authentication.user.id } })
)

export const get = ({ fetcher }) => (id) => useSWR(id && `/remote_assistant/calls/${id}`,
  (url) => fetcher.get(url).then(res => res && res.call_detail),
  { revalidateOnFocus: false, suspense:true }
)

export const dashboardData = ({ fetcher, authentication }) => () => useSWR(`/remote_assistant/users/${authentication.user.id}/dashboard`,
  (url) => fetcher.get(url).then(res => res),
  { revalidateOnFocus: false, suspense:true }
)

export const addInformation = ({ fetcher, authentication }) => async (callId, additionalInfo) => {

  const data = {
    ...additionalInfo,
    id: uuidv4(),
    file: '',
    extension: ''
  }
  await fetcher.put(`/remote_assistant/users/${authentication.user.id}/calls/${callId}/add_information`, data)
}

export const getUserLocation = ({ fetcher }) => (id) => useSWR(id && `/userauth/users/${id}/myplaces`,
  (url) => fetcher.get(url).then(res => res),
  { revalidateOnFocus: false, suspense:true }
)

export const getProcesses = ({ fetcher }) => (process_type_id) => useSWR(`/processes?process_type_id=${process_type_id}`,
  (url) => fetcher.get(url).then(res => res.processes),
  { revalidateOnFocus: false }
)