/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useEffect } from 'react'
import ReactExport from 'react-data-export'
import { useTranslation } from 'react-i18next'
import { useApi } from '../../hooks'
import moment from 'moment'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ExportExcel = ({filter, onFinish, type, catalogId}) => {
    const { t } = useTranslation()

    let dataList = ""
    const { calls, trainingAssessmentsReports, catalogs } = useApi()

    if (type == "evaluation"){
        const { data } = trainingAssessmentsReports.report({ filter })
        dataList = data
    }else if(type == "calls"){
        dataList = calls.report({ filter })
    }else if(type == "catalogs"){
        const catalogDetailList = catalogs.listDetails({ catalogId, filter })

        const productList = []
        catalogDetailList?.data?.views.map((view) => {
            view.products.map((product) => {
                product.views.map((pview) => {

                    productList.push({
                        date: moment(pview.date).format('DD/MM/YYYY'),
                        category: view?.category?.path,
                        product: product?.name,
                        views: pview.views,
                        view_ar: pview.views_ar
                    })
                })
            })
        })
        dataList = productList.length > 0 ? productList : null
    }

    useEffect(_ => onFinish(), [])

    return (
        dataList && (type == "calls" &&
        <ExcelFile filename={t('Reports.CallsReport.Filename')} hideElement={true}>
            {
                <ExcelSheet data={dataList && dataList.data && (dataList.data.map((item) => {
                    return {
                        id: item.id,
                        date: moment(item.started_at).format('L LTS'),
                        requester: item.users_from.name,
                        specialist: item.users_to.name,
                        duration: item.duration
                    }
                }))} name="Reports">
                    <ExcelColumn label="Id" value="id" />
                    <ExcelColumn label={t('Reports.CallsReport.DateAndHour')} value="date" />
                    <ExcelColumn label={t('Reports.CallsReport.Requester')} value="requester" />
                    <ExcelColumn label={t('Reports.CallsReport.Specialist')} value="specialist" />
                    <ExcelColumn label={t('Reports.CallsReport.CallDuration')} value="duration" />
                </ExcelSheet>
            }
        </ExcelFile>

        || type == "evaluation" &&

        <ExcelFile filename={t('Reports.TrainingAssessmentsReports.Filename')} hideElement={true}>
        {
            <ExcelSheet data={dataList && dataList.steps && (dataList.steps.map((item) => {
                return {
                    id: item.id,
                    process: item.processes.name,
                    date: moment(item.evaluation_date).format('L LTS'),
                    evaluated: item.users_evaluated.name,
                    evaluatedEmail: item.users_evaluated.email,
                    evaluator: item.users_evaluator.name,
                    percentage: item.percentage
                }
            }))} name="Reports">
                <ExcelColumn label="Id" value="id" />
                <ExcelColumn label={t('Reports.TrainingAssessmentsReports.Process')} value="process" />
                <ExcelColumn label={t('Reports.TrainingAssessmentsReports.Employee')} value="evaluated" />
                <ExcelColumn label={t('Reports.TrainingAssessmentsReports.EmailEvaluated')} value="evaluatedEmail" />
                <ExcelColumn label={t('Reports.TrainingAssessmentsReports.Evaluator')} value="evaluator" />
                <ExcelColumn label={t('Reports.TrainingAssessmentsReports.DateAndHour')} value="date" />
                <ExcelColumn label={t('Reports.TrainingAssessmentsReports.Percentage')} value="percentage" />
            </ExcelSheet>
        }
        </ExcelFile>

        || type == "catalogs" &&

        <ExcelFile filename={t('Catalogs.Details.Reports.Filename')} hideElement={true}>
        {
            <ExcelSheet data={dataList} name="Reports">
                <ExcelColumn label={t('Catalogs.Details.Reports.Date')} value="date" />
                <ExcelColumn label={t('Catalogs.Details.Reports.Category')} value="category" />
                <ExcelColumn label={t('Catalogs.Details.Reports.Product')} value="product" />
                <ExcelColumn label={t('Catalogs.Details.Reports.Views')} value="views" />
                <ExcelColumn label={t('Catalogs.Details.Reports.AugmentedReality')} value="view_ar" />
            </ExcelSheet>
        }
        </ExcelFile>
        )
    )
}

export default ExportExcel
