/* eslint-disable eqeqeq */
import { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { SearchOutlined, ClearOutlined } from '@ant-design/icons'

import { FormButton } from '../'

import { Modal, Table, Form, Input, Select, Row, Col, Checkbox, Space } from 'antd'

const { Column } = Table

const { Option } = Select

const StyledTable = styled(Table)`
  tr > th {
    background-color: ${props => props.theme.secondary.background || '#000'};
    color: ${props => props.theme.secondary.color || '#fff'};
  }
`

const ModalTitle = styled.div`
  color: ${props => props.theme.primary.color || '#000000'};
`

const SearchForm = styled(Form)`
  margin-bottom: 15px;
`

const FormItem = styled(Form.Item)`
  margin: 5px;
`

export const RelationshipModal = (props) => {
  const { t } = useTranslation()
  const [objSearchForm] = Form.useForm()

  const {
    keyPrefix,
    relationshipList,
    open,
    objectName,
    initialSelection,
    onModalClosed,
    onModalSubmit,
    columns,
    i18nPath,
    searchForm,
    loading,
    readonly = false
  } = props

  const [ selection, setSelection ] = useState( [] )
  const [ filteredRelationshipList, setFilteredRelationshipList ] = useState( [] )

  useEffect(() => {
    setFilteredRelationshipList( relationshipList )
    setSelection(initialSelection)
  }, [initialSelection, relationshipList])

  const saveChange = () => {
    onModalSubmit(selection)
    onClearFilter()
  }

  const cancelChange = () => {
    onModalClosed()
    onClearFilter()
  }

  const onRowSelected = ( id, selected ) => {
    let newSelection = new Set( [...selection] )
    if( selected ) {
      newSelection.add(id)
    } else {
      newSelection.delete(id)
    }
    setSelection( Array.from(newSelection) )
  }

  const onFilter = (criteria) => {

    const filteredRelationshipList = relationshipList.filter( element => {
      let match = true
      searchForm && searchForm.forEach( form => {
        const criterion = criteria[form.name]

        if( criterion ) {
          const value = element[form.name]
          if( form.options ) {
            match = match && value && value.find( item => item.id == criterion )
          }
          else {
            match = match && value && value.toLowerCase().includes(criterion.toLowerCase())
          }
        }

      })

      return match
    })

    setFilteredRelationshipList(filteredRelationshipList)
  }

  const onClearFilter = () => {
    objSearchForm.resetFields()
    onFilter({})
  }

  return (
    <Modal width='70%'
        visible={open}
        centered={true}
        maskClosable={false}
        title={
          (<>
            <ModalTitle>{t(`${i18nPath}.Title`)}</ModalTitle>
            <h5>{t(`${i18nPath}.Description`)}: <b>{objectName}</b></h5>
          </>)
        }
        cancelText={t(`${i18nPath}.CancelText`)}
        okText={t(`${i18nPath}.OKText`)}
        onOk={saveChange}
        onCancel={cancelChange}
        footer={!readonly && [
          <FormButton key='back' onClick={cancelChange}>
            {t(`${i18nPath}.CancelText`)}
          </FormButton>,
          <FormButton key='submit' type='primary' onClick={saveChange} loading={loading}>
            {t(`${i18nPath}.OKText`)}
          </FormButton>
        ]} >
      {/* <> */}
      { searchForm &&
        <SearchForm form={ objSearchForm } preserve={false} layout='horizontal' onFinish={criteria => onFilter(criteria)}>
          <Row>
            { searchForm.map( (item, index) =>
              <Col key={`${keyPrefix}_relationshipModal${index}`}>
                {
                  item.options &&
                  <FormItem name={item.name} label={item.title}>
                      <Select
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          (option.children &&
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                        }
                        style={{width: 150}}
                        value={item.value}>
                        <Option key='0' value={null}></Option>
                        {
                          item.options.map( ( option, index ) => <Option key={ `${index + 1}` } value={ `${ option.value }` }>{option.name}</Option> )
                        }
                      </Select>
                  </FormItem>
                }
                {
                  !item.options &&
                  <FormItem name={item.name} label={item.title}>
                    <Input value={item.value} placeholder={item.title} />
                  </FormItem>
                }
              </Col>
            )}
            <Col key={`${keyPrefix}_relationshipModal${searchForm.length}`}>
              <FormItem>
                <Space>
                  <FormButton onClick={ onClearFilter }>
                    {t('Search.Clear')} <ClearOutlined />
                  </FormButton>
                  <FormButton type='primary' htmlType='submit'>
                    {t('Search.OK')} <SearchOutlined />
                  </FormButton>
                </Space>
              </FormItem>
            </Col>
          </Row>
        </SearchForm>
      }
      <StyledTable scroll={{ y: 340 }} loading={loading}
          dataSource={ filteredRelationshipList.map( element => ( {key: `${element.id}`, ...element} ) ) } // `${key}` -> transforming key into string
          pagination={ false }>
        <Column title={t(`${i18nPath}.Allowed`)}
          key='selectItem'
          render={(_, record) =>
            <Checkbox key={`select_${record.id}`} checked={ selection.includes( record.id ) } onChange={ e => onRowSelected( record.id, e.target.checked ) } disabled={readonly}></Checkbox>
          } />
        { columns.map( col => <Column title={ col.title } dataIndex={ col.dataIndex } key={ col.dataIndex } /> ) }
      </StyledTable>
      {/* </> */}
    </Modal>
  )
}
