import React from 'react'
import { Space, Modal } from 'antd'
import QRCode from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import { FormButton } from '../Page/FormButton'
import { DownloadOutlined } from '@ant-design/icons'

export const QRCodeModal = ({ visible, onModalClose, value, footer }) => {
  const { t } = useTranslation()

  const downloadQRCode = () => {
    const canvas = document.getElementById('qr-code')
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')
    let downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = 'qr_code.png'
    downloadLink.click()
  }

  return (
    <Modal
      width={'min-content'}
      title={t('Admin.DynamicInstructions.Upload.QRCodeModalTitle')}
      footer={null}
      visible={visible}
      onCancel={onModalClose}>
      <Space direction='vertical' align='center'>
        <QRCode
          id='qr-code'
          size={350}
          includeMargin={true}
          value={value} />
        {
          footer &&
          <span>{footer}</span>
        }
        <FormButton onClick={downloadQRCode}>
          <DownloadOutlined />
          {t('Admin.DynamicInstructions.Upload.Download')}
        </FormButton>

      </Space>
    </Modal>
  )
}
