/* eslint-disable eqeqeq */
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { FormButton } from '../'
import React from 'react'

import { Modal, Form, Input, Space } from 'antd'
const { TextArea } = Input

const ModalTitle = styled.div`
  color: ${props => props.theme.primary.color || '#000000'};
`

const FormItem = styled(Form.Item)`
  margin: 5px;
`

export const CommentModal = (props) => {
  const { t } = useTranslation()
  const [commentForm] = Form.useForm()

  const {
    open,
    onModalClosed,
    onModalSubmit,
    title,
    commentText
  } = props

  const saveChange = (item) => {
    if(item.comment.trim() == ""){
      cancelChange()
    }else{
      onModalSubmit(item)
      cancelChange()
    }
  }

  const cancelChange = () => {
    commentForm.resetFields()
    onModalClosed()
  }

  commentForm.resetFields()

  return (
    <Modal width='70%'
        visible={open}
        centered={true}
        maskClosable={false}
        onModalClosed={cancelChange}
        title={
          (<>
            <ModalTitle>{title}</ModalTitle>
          </>)
        }
        onCancel={cancelChange}
        footer={false} >
        <Form form={ commentForm }  layout='horizontal' onFinish={(item) =>  saveChange(item)}>
          <Form.Item name='comment'
            rules={[
              { required: false, message: 'Required' },
              { max: 500, message: 'Max. 500' }
            ]}
          >
            <TextArea
              style={
                { minWidth: 200, minHeight: 200 }}
              rows={9}
              maxLength={500}
              showCount
              disabled={false}
              defaultValue={commentText}
            />
          </Form.Item>

          <FormItem>
            <Space>
              <FormButton onClick={ cancelChange }>
                {t('CommentModal.CancelButton')}
              </FormButton>
              <FormButton type='primary' htmlType='submit'>
                {t('CommentModal.SubmitButton')}
              </FormButton>
            </Space>
          </FormItem>
        </Form>
    </Modal>
  )
}
