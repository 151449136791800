import { useState, forwardRef, useImperativeHandle } from 'react'
import { Select } from 'antd'

import { useApi } from '../../hooks'

const { Option } = Select

export const SelectUser = forwardRef(({ selectedUsers, onSelect }, ref) => {
  const { users } = useApi()
  const [userSelected, setUserSelected] = useState('')

  useImperativeHandle(
    ref,
    () => ({
      clear: () => {
        setUserSelected('')
      }
    }),
  )

  const { data: allUsers, isValidating: isValidatingAllUsers } = users.list({ filter: '', initialPage: 1, initialPageSize: 1000 })
  const options = (allUsers && allUsers.users && allUsers.users.filter(user => !selectedUsers.find(u => u.users_id === user.id))) || []
  return (
    <Select autoClearSearchValue
        showSearch
        optionFilterProp='children'
        style={{ width: '100%' }}
        filterOption={(input, option) =>
          (option.children &&
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
        }
        value={userSelected}
        onSelect={(key, value) => {
          setUserSelected(value.children)
          onSelect(key)
        }}
        loading={!allUsers || isValidatingAllUsers}>
      {options.map( user =>
        <Option key={user.id} value={user.id}>{user.email}</Option>
      )}
    </Select>
  )
})
