/* eslint-disable no-mixed-operators */
/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useRef, useEffect, useState } from 'react'
import { Upload, notification, Button, Modal, Form, Input } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { FormButton } from '../../components'
import ImgCrop from 'antd-img-crop';

const { TextArea } = Input

const convertFileToBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = () => resolve(reader.result)
  reader.onerror = (error) => reject(error)
  reader.readAsDataURL(file)
})

export const UploadDefaultModal = (props) => {
  const {
    content,
    onUpload,
    onChange,
    previewVisible,
    handleCancel,
    fileList,
    onFinish,
    type,
    isSubmitting,
    multiple,
    maxSize = null,
    title = null } = props

  const formRef = useRef(null)
  const { t } = useTranslation()
  const [uploadSuccess, setUploadSuccess] = useState(true)
  const [loading, setLoading] = useState(false)

  const formatByType = {
    'A': '.mp3',
    'V': '.mp4',
    'I': '.jpg, .jpeg, .png',
    'ICROP': '.jpg, .jpeg, .png',
    '3D': '.obj, .fbx',
    'P': '.pdf',
    'ALL': '*.*'
  }

  const [localUploadList, setLocalUploadList] = useState([])

  useEffect(() => {
    formRef.current && formRef.current.resetFields()
    if (onUpload != undefined && onUpload.base64 || previewVisible == false) {
      //onUpload({})
      setLocalUploadList([])
    }
  }, [previewVisible, onUpload])

  useEffect(() => {
    onUpload(localUploadList && localUploadList.length > 1 ? localUploadList : localUploadList.length == 1 ? localUploadList[0] : '');
  }, [ localUploadList ])

  // eslint-disable-next-line no-unused-vars
  const throwNotificationError = (message) => {
    notification.error({
      message: message,
      duration: 3
    })
  }

  const Label = styled(Form.Item)`
    display: block;
  `;

  const beforeUpload = async (file, fileUploadList) => {
    //upload do tipo multiplos arquivos = concatena na lista de arquivos
    //upload do tipo arquivo único = sobrescreve lista de arquivos
    let convertedList = [];
    let files = multiple ? fileUploadList : [file];



    // Verifica se o tamanho do arquivo excede o limite
    const fileSize = file.size / 1024; // Tamanho do arquivo em KB
    if (!!maxSize && fileSize > maxSize && !multiple) {
      // Mostra uma notificação de erro
      throwNotificationError(t('Workflow.Step.Modal.MaxSizeError', { size: maxSize }));
      setUploadSuccess(false);
      setLoading(false);
      return false; // Tamanho do arquivo excede o limite
    }

    let type = '';
    if (file.type === '') {
      // Tenta determinar o tipo do arquivo pelo conteúdo se o tipo não estiver definido
      type = file.type.substring(file.type.indexOf('/') + 1);
      if (excel) {
        type = 'xlsx';
        return false;
      }
    }

    setLoading(true);
    for (let file of files){
      // const isMp4 = file.type === 'video/mp4'
      const isPdf = file.type === 'application/pdf'
      const isMp3 = (file.type === 'audio/mp3' || file.type === 'audio/mpeg')
      const isPngOrJpg = (file.type === ('image/jpeg') || file.type === ('image/jpg') || file.type === ('image/png'))
      const isICROP = (file.type === ('image/jpeg') || file.type === ('image/jpg') || file.type === ('image/png'))
      // const fileSize = (file.size / 1024 / 1024)

      onUpload('')
      /*
        //limitacao de 10mb
          if (fileSize > 10) {
          throwNotificationError(t('Workflow.Step.Modal.MaxSizeError', { size: 10 }))
          setUploadSuccess(false)
          return false
        }
      */

      let type = ''

      if(file.type == ""){
        if(file.name.toLowerCase().endsWith(".obj")){
          type = 'obj'
        }
        if(file.name.toLowerCase().endsWith(".fbx")){
          type = 'fbx'
        }else{
          type = file.name.split('.').pop()
        }
      }

      if (file.type == "") {
        if (file.name.toLowerCase().endsWith(".obj")) {
          type = 'obj'
        }
        if (file.name.toLowerCase().endsWith(".fbx")) {
          type = 'fbx'
        }
      } else {
        type = file.type.substring(file.type.indexOf('/') + 1)
        if (isMp3) {
          type = 'mp3'
        } if (isPdf) {
          type = 'pdf'
        } if (isICROP && type === 'jpeg') {
          type = 'jpg'
        } else if (isPngOrJpg && type === 'jpeg') {
          type = 'jpg'
        }
      }

      let formatedFile = file
/*
      //caso precise manipular o type do file raw
      if (file.type == ""){
        const newBlob = new Blob([file],{
          type: file.type? file.type : file.name.split('.').pop()
        })
        formatedFile = new File([newBlob], file.name, {type: 'model/obj'})
      }
 */
      await convertFileToBase64(formatedFile).then(data => {
        convertedList = [...convertedList,
          {
            type: type,
            base64: data,
            name: file.name ? file.name.replace(/\.[^/.]+$/, "") : '',
            uid: file.uid,
            fileRaw: formatedFile,
          }
        ]
      }).catch(e => {
        console.log(e);
      })
    }


    setLocalUploadList(convertedList);
    setUploadSuccess(true)
    setLoading(false)
    return true
  }

  const handleFileRemoval = (file) => {
    setLocalUploadList(localUploadList.filter(f=> f.uid != file.uid))
  }

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess(uploadSuccess === true ? 'ok' : 'error')
    }, 0)
  }

  const getModalContentByType = () => {
    switch (type) {
      case 'T':
        return <>
          <label htmlFor="content_long">{t('Workflow.Step.Types.instruction')}</label>
          <Form.Item name='content_long' rules={[
            { required: false, message: 'Required' },
            { max: maxSize, message: `Max. ${maxSize}` }
          ]}>
            <TextArea rows={4} placeholder={t('Workflow.Step.Modal.ContentLong')} />
          </Form.Item>
        </>

      case 'I':
        return <Upload
          accept={formatByType[type]}
          customRequest={dummyRequest}
          beforeUpload={beforeUpload}
          onChange={(item) => onChange(item)}
          fileList={fileList}
          multiple={multiple ? multiple : false}

        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>

      case 'ICROP':
        return <ImgCrop rotate quality={1}>
          <Upload
            accept={formatByType[type]}
            customRequest={dummyRequest}
            beforeUpload={beforeUpload}
            onChange={(item) => onChange(item)}
            fileList={fileList}
            multiple={multiple ? multiple : false}

          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </ImgCrop>

      case 'P':
        return <Upload
          accept={formatByType[type]}
          customRequest={dummyRequest}
          beforeUpload={beforeUpload}
          onChange={(item) => onChange(item)}
          fileList={fileList}
          multiple={multiple ? multiple : false}

        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>

      case '3D':
        return <Upload
          accept={formatByType[type]}
          customRequest={dummyRequest}
          beforeUpload={beforeUpload}
          onChange={(item) => onChange(item)}
          fileList={fileList}
          multiple={multiple ? multiple : false}
          maxSize={maxSize}
        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>

      case 'alternative': //Todo: i18n
        return <>
          <label htmlFor="content_short">
            {t('Workflow.Step.Modal.Alternative')}
          </label>
          <Form.Item name='content_short' rules={[
            { required: false, message: 'Required' },
            { max: maxSize, message: `Max. ${maxSize}` }
          ]} >
            <TextArea rows={2} placeholder={t('Workflow.Step.Modal.ContentShort')} />
          </Form.Item>

          <label htmlFor="information">
            {t('Workflow.Step.Modal.AdditionalInformation')}
          </label>
          <Form.Item name='information' rules={[
            { required: false, message: 'Required' },
            { max: maxSize, message: `Max. ${maxSize}` }
          ]} >
            <TextArea rows={4} placeholder={t('Workflow.Step.Modal.Information')} />
          </Form.Item>
        </>

      default:
        return <Upload
          accept={formatByType[type]}
          customRequest={dummyRequest}
          beforeUpload={beforeUpload}
          onChange={(item) => onChange(item)}
          fileList={fileList}
          multiple={multiple ? multiple : false}
          onRemove={handleFileRemoval}
          maxSize={maxSize}
        >
          <Button loading={loading} icon={<UploadOutlined />}>Upload</Button>
        </Upload>
    }
  }

  const handleFinish = (item) => {
    localUploadList.length > 0 ?? setLocalUploadList([])
    onFinish(item)
  }

  return (
    <Modal
      width={'fit-content'}
      style={{ maxWidth: '500px', minWidth: '300px' }}
      visible={previewVisible}
      title={props.title ?? t('Workflow.Step.Modal.Creation')}
      footer={null}
      onCancel={handleCancel}
      forceRender={true}
      maskClosable={false}
    >
      <Form ref={formRef} name='complement_modal' onFinish={(item) => handleFinish(item)} initialValues={content}>
        <label htmlFor="title">{title||t('Workflow.Step.Title')}</label>
        {
          !props.hasNoTitle &&
          <Label
            name='title'
            rules={[{ required: true, message: 'required' }]}
          >
            <Input placeholder={t('Workflow.Step.Title')} />
          </Label>
        }

        {getModalContentByType(type)}
        {props.children}


        {
          maxSize &&
          <small className='sizes'> Tamanho máximo: {maxSize} megas </small>
        }

        <div style={{ overflow: 'auto', width: '100%' }}>
          <FormButton loading={isSubmitting}  style={{ float: 'right', marginTop: 15 }} htmlType='submit' type='primary'>{t('Workflow.Step.Modal.Create')}</FormButton>
          {props.extraButtons}
        </div>
      </Form>
    </Modal>
  )
}