import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import moment from 'moment'

import 'moment/locale/es'
import 'moment/locale/pt'

const isDev = process.env.NODE_ENV !== 'production'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      lookupQuerystring: 'lang'
    },
    fallbackLng: 'en',
    debug: isDev,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  }, _ => {
    moment.locale(i18n.language)
  })

export default i18n
