import React, {useState} from 'react';
import { useTranslation } from 'react-i18next'
import { Space, Modal, Select } from 'antd'
import { FormOutlined } from '@ant-design/icons'

import {  FormButton } from '../../components'


export const SelectModal = ({ title, visible, onModalClose, onSelect, options }) => {
  const [selection, setSelection] = useState()
  const [showValue, setShowValue] = useState()
  const { t } = useTranslation()
  const { Option } = Select

  const onSubmit = () => {
    onSelect && onSelect(selection)
  }

  const onChange = (value, option) => {
    setShowValue(option.children)
    setSelection(value)
  }

  return (
    <Modal
      width={'max-content'}
      title={title}
      footer={null}
      visible={visible}
      onCancel={onModalClose}
    >

      <Space direction='vertical' align='center'>
        <Select
          showSearch
          value={showValue}
          style={{
            width: 350,
          }}
          optionFilterProp='children'
          onChange={onChange}
          filterOption={(input, option) =>
          (option.children &&
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
          }
        >
          { options  && options.map(el =>(
            <Option key={el.key} value={el.value}>{el.name}</Option>
          ))}

        </Select>
        <FormButton type='primary' htmlType='submit' onClick={onSubmit}>
          {t('Admin.Groups.Form.SubmitButton')} <FormOutlined />
        </FormButton>
      </Space>
    </Modal>

  );
}


