import React from 'react'
import { Layout, Button } from 'antd'
import styled from 'styled-components/macro'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import { AwesomeIcon } from '../Icons/Icons'
import { Page }  from './Page'

const { Header, Content, Sider } = Layout

const DeleteIcon = styled(AwesomeIcon)`
  cursor: pointer;
  color: ${props => props.theme.secondary.color};
  font-size: 20px;
`

const Container = styled(Sider)`
  height: 100%;
  background: unset;
  margin-left: 10px;
  border: 1.2px solid ${props => props.theme.secondary.background};
`

const Title = styled(Header)`
  color: ${props => props.theme.secondary.color};
  background-color: ${props => props.theme.secondary.background};
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  padding-right: 0px;
  font-size: 18px;
`

const Body = styled(Content)`
  height: calc(100% - 50px);
  padding: 5px 20px;
  overflow-y: auto;
`

export const Detail = ({children, title, onDelete, deleteTitle, ...props}) => (
  <Container width={400} {...props}>
    <Title>
      {title}
      { onDelete &&
        <Page.Actions>
          <Button type='text'>
            <DeleteIcon title={deleteTitle} icon={faTrash} onClick={onDelete}/>
          </Button>
        </Page.Actions>
      }
    </Title>
    <Body>
      {children}
    </Body>
  </Container>
)
