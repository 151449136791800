import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { ConfigProvider } from 'antd'
import ptBr from 'antd/es/locale/pt_BR'
import enUs from 'antd/es/locale/en_US'
import esEs from 'antd/es/locale/es_ES'

const AntdConfigProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const [language, setLanguage] = useState()

  useEffect(() => {
    if (i18n.language.includes('pt')) {
      setLanguage(ptBr)
    } else if (i18n.language.includes('es')) {
      setLanguage(esEs)
    } else {
      setLanguage(enUs)
    }
  }, [i18n.language])

  return (
    <ConfigProvider locale={language} input={{autoComplete: 'off'}} >
      {children}
    </ConfigProvider>
  )
}

export default AntdConfigProvider
