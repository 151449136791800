import { useRef, useMemo,  useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {Card, Radio, Typography, Avatar, notification, Alert} from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import userDefault from '../../images/userDefault.png'
import { useApi } from '../../hooks'
import {FormButton} from '..'
import { SelectUser } from './SelectUser'

const { Meta } = Card
const RadioButton = styled(Radio)`
  display: block;
  height: 30px;
  line-height: 30px;
`

export const AddUser = ({ userList, setUserList, loading = false, scheduleId, ...props }) => {
  const { users, authentication } = useApi()
  const { t } = useTranslation()

  const [cardRole, setCardRole] = useState('guest')

  const [userId, setUserId] = useState(scheduleId ? null : authentication.user.id)

  let { data: user, isValidating } = users.get(userId)

  const { data: loggedUser } = users.get(authentication.user.id)
  const [useLoggedUser, setUseLoggedUser] = useState(false)

  const selectUsersRef = useRef()

  const hasRequester = useMemo(
    _ => scheduleId || userList.find(user => user.users_id === authentication.user.id),
    [scheduleId, userList, authentication.user.id]
  )

  const checkIfExistRequesterAndSpecialist = _ => {
    if (cardRole !== 'guest') {
      return userList.find(u => u.role === cardRole)
    }
    return false
  }

  const handleAddUser = _ => {
    if (!hasRequester) user = loggedUser

    if (userList.find(u => u.users_id === user.id && u.users_id !== authentication.user.id)){
      notification.error({
        message: t('RemoteAssistant.Settings.Schedules.Form.UserAlreadyExistsError'),
        duration: 3
      })
    } else if (checkIfExistRequesterAndSpecialist()) {
      notification.error({
        message: `${t('RemoteAssistant.Settings.Schedules.Form.ProfileError')} ${t(`RemoteAssistant.Settings.Schedules.Form.${cardRole}`)}`,
        duration: 3
      })
    } else {

      setUserList([
        ...userList.filter(u => u.users_id !== user.id),
        {
          id: uuidv4(),
          name: user.name,
          photo_url: user.photo_url,
          users_id: user.id,
          state: user.state,
          role: cardRole
        }
      ])
      setUseLoggedUser(false)
      setUserId(null)
      setCardRole('guest')
      selectUsersRef.current && selectUsersRef.current.clear()
    }
  }

  useMemo(_ => {
    if (!hasRequester) {
      setUseLoggedUser(true)
    }
  },[hasRequester])

  return (
    <>
      { !hasRequester ?
        <Alert message={t('RemoteAssistant.Settings.Schedules.Form.AddRequesterFirst')} type='info' showIcon />
      :
        <SelectUser selectedUsers={userList} onSelect={setUserId} ref={selectUsersRef} />
      }

      {(userId || useLoggedUser) &&
        <>
          <Card style={{ width: '100%' }} loading={(!userId? !useLoggedUser : (!user || isValidating)) } {...props}>
            { (user || loggedUser ) &&
              <>
                <Meta
                  avatar={<Avatar src={((user && user.photo_url) || (!userId && loggedUser && loggedUser.photo_url)) || userDefault} />}
                  title={(user) ? user.name : (loggedUser) ? loggedUser.name : t('RemoteAssistant.Settings.Schedules.Form.Name')}
                  description={(user) ? user.state : (loggedUser) ? loggedUser.state : t('RemoteAssistant.Settings.Schedules.Form.Locality')}
                  style={{ marginBottom: 15 }} />

                <Typography.Text
                  style={{ display: 'block', marginBottom: '5px' }}
                  strong
                  type='secondary'>{t('RemoteAssistant.Settings.Schedules.Form.RoleInTheMeeting')}</Typography.Text>
                <Radio.Group onChange={(e) => setCardRole(e.target.value)} value={cardRole}>
                  <RadioButton value='requester'>{t('RemoteAssistant.Settings.Schedules.Form.requester')}</RadioButton>
                  <RadioButton value='specialist'>{t('RemoteAssistant.Settings.Schedules.Form.specialist')}</RadioButton>
                  <RadioButton value='guest'>{t('RemoteAssistant.Settings.Schedules.Form.guest')}</RadioButton>
                  <RadioButton value='willNotParticipate'>{t('RemoteAssistant.Settings.Schedules.Form.willNotParticipate')}</RadioButton>
                </Radio.Group>
              </>
            }
          </Card>

          <FormButton disabled={(!userId? !useLoggedUser : (!user || isValidating))}
              style={{ marginBottom: 15 }}
              type='primary'
              block
              onClick={handleAddUser}>
            {t('RemoteAssistant.Settings.Schedules.Form.Add')}
          </FormButton>
        </>
      }


    </>
  )
}
