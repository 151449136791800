import { Fragment, useState } from 'react'
import styled from 'styled-components/macro'
import { List, Avatar, Divider, Space, Button, Tooltip, Collapse } from 'antd'

const { Panel } = Collapse

const Buttons = styled.div`
  height: 20px;
`

const MainTitle = styled.div`
  font-size: 11px;
`

const MainValue = styled.h2`
  color: ${props => props.theme.primary.color || '#000000'};
  margin: 0;
`

const Content = styled.div`
  text-align: right;
`

const ContentTitle = styled.span`
  font-size: 0.6em;
`

const CustomCollapse = styled(Collapse)`
  border-bottom: 0;

  & + div {
    border-top: 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse-item > .ant-collapse-header {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 30px;
  }
  .ant-collapse-item > .ant-collapse-header > span {
    display:block;
    float:left;
  }
  .ant-collapse-item > .ant-collapse-header > li {
    width:95%;
  }

  > div > div > div {
    margin: auto 0;
  }

  > div > div > span > li {
    border: 0;
  }
`

const ListItem = styled(List.Item)`
  padding: 10px;
  cursor: pointer;
  background-color: #F9F9F9;
`

const defaultImageSize = 32

export const CrudRow = (props) => {
  const [activeKey, setActiveKey] = useState([])

  const ButtonWithTooltip = ({ item }) => {
    const [tooltipVisible, setTooltipVisible] = useState(false)
    return (
      <Tooltip title={item.title} key={item.title} visible={tooltipVisible}>
        <Button type='link' shape='circle' size={20} icon={item.icon}
          onMouseEnter={_ => setTooltipVisible(true)}
          onMouseLeave={_ => setTooltipVisible(false)}
          onClick={e => {
            if (!item.dontStopPropagation || (activeKey.length > 0)) {
                e.stopPropagation()
                e.preventDefault()
            }
            item.onClick()
            setTooltipVisible(false)
          }} />
      </Tooltip>
    )
  }

  const CustomItem = (props) => {
    return (
      <ListItem
        style={props.style}
        onClick={props.onClick}
      >
        <List.Item.Meta
          avatar={props.leftImage &&
            <Avatar
              size={props.leftImage.size || defaultImageSize}
              src={props.leftImage.url}
              alt={props.leftImage.tooltip}>
            </Avatar>
          }
          title={props.main &&
            <MainTitle>{props.main.title}</MainTitle>
          }
          description={props.main &&
            <MainValue>{props.main.value}</MainValue>
          } />
        <Space size={5}>
          {props.id &&
            <Content>
              <ContentTitle>{props.id.title}</ContentTitle><br />
              {props.id.value}
            </Content>
          }

          {props.info && props.info.map((item, index) =>
            <Fragment key={index}>
              <Divider type='vertical' />
              <Content>
                <ContentTitle>{item.title}</ContentTitle><br />
                {item.value}
              </Content>
            </Fragment>
          )}

          <Divider type='vertical' />

          <Buttons>
            {props.buttons && props.buttons.map((item, index) =>
              <ButtonWithTooltip item={item} key={index} />
            )}
          </Buttons>
        </Space>
      </ListItem>
    )
  }

  const callback = (key) => {
    setActiveKey(key)
  }

  return (
    <>
      {props.collapse ?
        <CustomCollapse
          onChange={callback}
          style={{...props.style, borderTop: '1px solid #d9d9d9'}}
        >
          <Panel
            key={props.index}
            header={<CustomItem {...props} />}>
            <List>
              {props.children}
            </List>
          </Panel>
        </CustomCollapse> :
        <CustomItem
          {...props}
          style={{ borderRight: '1px solid #d9d9d9', borderLeft: '1px solid #d9d9d9' }}
        />
      }
    </>
  )
}
