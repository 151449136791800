import i18n from 'i18next'

export const useLanguage = () => {
  if (i18n.language) {
    const [lang] = i18n.language.split('-')
    return lang
  }

  return null
}
