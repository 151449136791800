import { List } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { SortableContainer } from 'react-sortable-hoc'
import { faTrash, faEye, faPen } from '@fortawesome/free-solid-svg-icons'
import { SortableItem } from './SortableItem'

import { AwesomeIcon } from '..'

const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

export const SortableList = SortableContainer((props) => {
	return (
		<List style={{ paddingLeft: 30 }} loading={props.loading && spinIcon}>
			{props.items.map((item, index) =>
				<SortableItem
					key={index}
					index={index}
					value={item}
					actions={
						[
							<AwesomeIcon onClick={(e) => {
								props.handlePreview({
									visible: true,
									url: item.url,
									content_short: item.content_short,
									content_long: item.content_long,
									information: item.information,
									type: item.type,
									title: item.title
								})
							}} icon={faEye} />,
							<AwesomeIcon onClick={(e) => {
								props.handleEdit(item)
							}} icon={faPen} />,
							<AwesomeIcon onClick={(e) => {
								props.handleRemove(item.id)
							}} icon={faTrash} />
						]
					}
				/>
			)}
		</List>
	)
})
