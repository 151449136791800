/* eslint-disable eqeqeq */

import * as users from './users'
import * as deviceModels from './deviceModels'
import * as groups from './groups'
import * as tools from './tools'
import * as devices from './devices'
import * as epis from './epis'
import * as pictograms from './pictograms'
import * as calls from './calls'
import * as places from './places'
import * as navigation from './navigation'
import * as ar from './augmentedReality'
import * as schedules from './schedules'
import * as orders from './orders'
import * as meetings from './meetings'
import * as alerts from './alerts'
import * as dynamicInstructions from './dynamicInstructions'
import * as processes from './processes'
import * as steps from './steps'
import * as workflows from './workflows'
import * as teams from './teams'
import * as trainingAssessmentsReports from './trainingAssessmentsReports'
import * as trainingPlanningReports from './trainingPlanningReports'
import * as trainingRecertification from './trainingRecertification'
import * as equipments from './equipments'
import { useAuthConfig } from '../hooks'
import { Auth } from '@aws-amplify/auth'
import jwt_decode from "jwt-decode";
import { getClient } from '../hooks/useClient'
import * as constructions from './constructions'
import * as constructionActivities from './constructionActivities'
import * as installationLocations from './installationLocations'
import * as catalogs from './catalogs'
import * as complianceHistory from './complianceHistory'

const { client, whitelabel } = getClient()

export const authenticate = ({setAuthentication, fetcher}) => async (accessToken, user, source = 1, callback = setAuthentication) => {
  if(client != 'vale'){
    await callback(accessToken, user, source)
  }else{
    const { arvisTokenUrl } = useAuthConfig()
    const data = await fetcher.get(arvisTokenUrl, {
      headers: {
        'Authorization': accessToken
      },
      params: {
        source_id: source
      }
    })
    await callback(data.jwt, data.user, source)
  }

}

/*export const authenticate = ({setAuthentication, fetcher}) => async (jwt, user, source = 1, callback = setAuthentication) => {
  await callback(jwt, user, source)
}*/

export const authenticateMultiClients = ({fetcher}) => async (accessToken) => {
  const customerPath = (whitelabel && whitelabel.split('.').length > 1) ? (whitelabel == 'demo.dev' ? 'demo' : whitelabel) : 'demo'
  const data = await fetcher.get('/login/session', {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': accessToken,
      'customer_path' : customerPath
    },params: {}
  })

  return data
}

export const defineCustomer = ({fetcher}) => async (customerId, userId, jwt, user) => {
  const data = await fetcher.put('/login/session',{
    customer_id : customerId,
    user_id : userId
  } ,{
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwt
    }
  })
  return data
}

export const logout = ({setAuthentication}) => async () => {
  const config = useAuthConfig()

  if( config.type === 'cognito' ) {
    Auth.configure(config)
    await Auth.signOut()
  }

  setAuthentication(null, null, null)
}

export const isCustomer = ({authentication}) => () => {
  const decoded = jwt_decode(authentication.token)

  if(decoded) {
    return decoded.user_management === "customer"
  }

  return false
}

export const isAuthenticated = ({authentication}) => () => {
  //TODO make a call to validate token before returning based on session
  return !!(authentication.token && authentication.user)
}

export { users, groups, tools, deviceModels, devices, epis, pictograms, navigation, ar, places, calls, schedules, meetings, alerts, dynamicInstructions, processes, steps, workflows, equipments, teams, orders, trainingAssessmentsReports, trainingPlanningReports, trainingRecertification, constructions, constructionActivities, installationLocations, catalogs, complianceHistory}
