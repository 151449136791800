import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import Moment from 'moment'

export const Container = styled.li`
  display: ${props => props.orientation === 'vertical' ? 'block' : 'inline-block'};
`
export const LanguageImage = styled.img`
  height: auto;
  width: 40px;
  cursor: pointer;
  opacity: ${props => props.selected ? '1.0' : '0.5'};
  :hover {
    opacity: 1.0;
  }
`

const LanguageLink = ({ language, image, orientation }) => {
  const { i18n } = useTranslation()

  return (
    <Container orientation={orientation}>
      <LanguageImage
        selected={i18n.language.includes(language)}
        onClick={(e) => {
            Moment.locale(language)
            i18n.changeLanguage(language)
          }
        }
        src={image}
        alt={language} />
    </Container>
  )
}

export default LanguageLink
