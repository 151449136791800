/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from 'react'
import { Upload, notification, Button, Modal, Form, Input } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { FormButton } from '../../components'

const convertFileToBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = () => resolve(reader.result)
  reader.onerror = (error) => reject(error)
  reader.readAsDataURL(file)
})

export const UploadExcel = (props) => {
  const {
    content,
    onUpload,
    onChange,
    previewVisible,
    handleCancel,
    fileList,
    onFinish,
    type,
    isSubmitting,
    maxSize // 1 = 1 mega
  } = props

  const formRef = useRef(null)
  const { t } = useTranslation()
  const [uploadSuccess, setUploadSuccess] = useState(true)
  const formatByType = {
    'E': '.xlsx'
  }

  useEffect(() => {
    formRef.current && formRef.current.resetFields()
    if(onUpload && onUpload.base64) {
      onUpload({})
    }
  }, [previewVisible, onUpload])

  const throwNotificationError = (message) => {
    notification.error({
      message: message,
      duration: 3
    })
  }

  // const beforeUpload = async file => {
  //   const excel = file.type === 'excel/xlsx'

  //   onUpload('')

  //   const fileSize = (file.size / 1024 )
  //   const maxSizeFile = maxSize ? (maxSize * 1024) : 10;


  //   console.log(`${fileSize} > ${maxSizeFile}`)
  //   if (fileSize > maxSizeFile) {
  //     throwNotificationError(t('Workflow.Step.Modal.MaxSizeError', { size: maxSizeFile / 1024 }))
  //     setUploadSuccess(false)
  //     return false
  //   }

  //   let type = ''

  //   if(file.type === ""){
  //     type = file.type.substring(file.type.indexOf('/') + 1)
  //     if (excel) {
  //       type = 'xlsx'
  //       return false
  //     }
  //   }

  //   await convertFileToBase64(file).then(data => {
  //     onUpload({
  //       content: data
  //     })
  //   })

  //   setUploadSuccess(true)
  //   return true
  // }


  const beforeUpload = async file => {
    // Verifica se o arquivo é do tipo 'excel/xlsx'
    const excel = file.type === 'excel/xlsx';

    // Verifica o tamanho máximo permitido em KB
    const maxSizeFile = maxSize * 1024; // Tamanho máximo em bytes

    // Verifica se o tamanho do arquivo excede o limite
    const fileSize = file.size / 1024; // Tamanho do arquivo em KB

    if (fileSize > maxSize) {
      // Mostra uma notificação de erro
      throwNotificationError(t('Workflow.Step.Modal.MaxSizeError', { size: maxSize }));
      setUploadSuccess(false);
      return false; // Tamanho do arquivo excede o limite
    }

    let type = '';

    if (file.type === '') {
      // Tenta determinar o tipo do arquivo pelo conteúdo se o tipo não estiver definido
      type = file.type.substring(file.type.indexOf('/') + 1);
      if (excel) {
        type = 'xlsx';
        return false;
      }
    }

    try {
      const data = await convertFileToBase64(file);
      onUpload({
        content: data,
      });
      setUploadSuccess(true);
      return true; // Upload bem-sucedido
    } catch (error) {
      // Tratar erros na conversão ou no upload, se necessário
      console.error('Erro ao converter ou fazer o upload do arquivo:', error);
      setUploadSuccess(false);
      return false;
    }
  }

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess(uploadSuccess === true ? 'ok' : 'error')
    }, 0)
  }

  const getModalContentByType = () => {
    switch (type) {
      case 'E':
        return <Upload
          accept={formatByType[type]}
          customRequest={dummyRequest}
          beforeUpload={beforeUpload}
          onChange={(item) => onChange(item)}
          fileList={fileList}
          multiple={false}
        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>

      default:
        return <Upload
          accept={formatByType[type]}
          customRequest={dummyRequest}
          beforeUpload={beforeUpload}
          onChange={(item) => onChange(item)}
          fileList={fileList}
          multiple={false}
        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
    }
  }

  return (
    <Modal
      width={'fit-content'}
      style={{maxWidth: '500px', minWidth: '300px'}}
      visible={previewVisible}
      title={props.title ?? t('Workflow.Step.Modal.Creation')}
      footer={null}
      onCancel={handleCancel}
      forceRender={true}
      maskClosable={false}
    >

      <Form ref={formRef} name='complement_modal' onFinish={onFinish} initialValues={content}>
        {
          !props.hasNoTitle &&
          <Form.Item
            name='title'
            rules={[{ required: true, message: 'required' }]}>
            <Input
              placeholder={t('Workflow.Step.Title')}
            />
          </Form.Item>
        }

        {getModalContentByType(type)}
        {
          maxSize &&
          <small className='sizes'> Tamanho máximo: {maxSize} megas </small>
        }
        {props.children}

        <div style={{ overflow: 'auto', width: '100%' }}>
          <FormButton loading={isSubmitting} style={{ float: 'right', marginTop: 15 }} htmlType='submit' type='primary'>{t('Workflow.Step.Modal.Create')}</FormButton>
          {props.extraButtons}
        </div>
      </Form>
    </Modal>
  )
}
