export const suspend = (promise) => {
  let status = 'pending'
  let result

  let suspender = promise.then(res => {
    status = 'success'
    result= res
  }).catch(err => {
    status = 'error'
    result = err
  })

  return {
    read: () => {
      if (status === 'pending') throw suspender
      else if (status === 'error') throw result
      else if (status === 'success') return result
    }
  }
}

export const wrapPromise = (func) => new Promise( func )
