import { usePaginatedSWR, useSWR } from '../hooks'
import { v4 as uuidv4 } from 'uuid'
// import { users } from '.';

// const ordersList = require('../api/stubs/ordersList.json');
// const orderDetail = require('../api/stubs/orderDetail.json');
// const orderGroups = require('../api/stubs/orderGroups.json');
// const orderAttributions = require('../api/stubs/orderAttributions.json');
// const orderUsers = require('../api/stubs/orderUsers.json');
// const orderDistributions = require('../api/stubs/orderDistribution.json')
// const orderSteps = require('../api/stubs/orderSteps.json')
const defaultFilter = {}

export const settings = ({ fetcher, authentication }) => (id, suspense = false) => useSWR(id && `/processes/orders/settings?process_type_id=1`,
  (url) => fetcher.get(url).then(res => res.settings).catch(e => console.log('ERROR', e)),
  { revalidateOnFocus: false, suspense }
)
export const list = ({ fetcher }) => ({ filter = defaultFilter, initialPage = 1, initialPageSize = 10 } = {}) => usePaginatedSWR(
  '/processes/orders', filter, initialPage, initialPageSize,
  (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, ...filter } })
)
/*
export const groups = ({ fetcher, authentication }) => () => {
  //await fetcher.get(`/remote_assistant/groups?active=1`)
  return orderGroups
} */

export const groupAssignments = ({ fetcher }) => (orderId) => useSWR(orderId && `processes/orders/${orderId}/assignments`,
  (url) => fetcher.get(url).then(res => res.groups),
  { revalidateOnFocus: false }
)

export const linkToGroups = ({ fetcher }) => async (orderId, groups) => {
  await fetcher.post(`/processes/orders/${orderId}/assignments`, groups)
}

export const get = ({ fetcher }) => (orderId) => useSWR(orderId && `/processes/orders/${orderId}`,
  (url) => fetcher.get(url).then(res => res.orders),
  { revalidateOnFocus: false }
)

export const update = ({ fetcher, authentication }) => async order => {
  await fetcher.put(`/remote_assistant/users/${authentication.user.id}/order/${order.id}`, order)
}

export const create = ({ fetcher, authentication }) => order => fetcher.post(`/remote_assistant/users/${authentication.user.id}/order`, { ...order, id: uuidv4() })

export const remove = ({ fetcher, authentication }) => order => fetcher.delete(`/remote_assistant/users/${authentication.user.id}/order/${order.id}`)

export const accept = ({ fetcher }) => async (id) => {
  await fetcher.put(`remote_assistant/order/${id}/accept`)
}

export const reject = ({ fetcher }) => async (id) => {
  await fetcher.put(`remote_assistant/order/${id}/reject`)
}

export const getGroupsAndProcesses = ({ fetcher }) => ({ order_id, suspense = false }) => useSWR(`/processes/orders/${order_id}`,
  (url) => fetcher.get(url).then(res => res.orders),
  { revalidateOnFocus: false, suspense }
)

export const getTeams = ({ fetcher }) => (group_id) => useSWR(`/remote_assistant/groups?group_id=${group_id}`,
  (url) => fetcher.get(url).then(res => res.groups),
  { revalidateOnFocus: false }
)

export const getUsers = ({ fetcher }) => ({ groupId, teamId }) => {
  let setUrl = ''
  if (groupId) {
    if (teamId) {
      setUrl = `/remote_assistant/groups/${groupId}/teams/${teamId}`
    } else {
      setUrl = `/remote_assistant/groups/${groupId}/contacts?assigned=1`
    }
  }
  return useSWR(setUrl,
    (url) => fetcher.get(url).then(res => {
      if (groupId) {
        if (teamId) {
          return res.teams.users
        }

        return res.group_users[0].map(group_user => ({
          id: group_user.users.id,
          name: group_user.users.name,
          photo_url: group_user.users.url,
          email: group_user.users.email
        }))
      }
    }),
    { revalidateOnFocus: false }
  )
}

export const getDistributions = ({ fetcher, authentication }) => ({ order_id, processes_id, revisions_id, suspense = false }) => useSWR(`/processes/orders/${order_id}/distributions`,
  (url) => fetcher.get(url).then(res => res.distributions),
  { revalidateOnFocus: true, suspense }
)


export const getSteps = ({ fetcher, authentication }) => ({ processes_id, revisions_id, suspense = false }) => useSWR(`/processes/${processes_id}/revisions/${revisions_id}/steps`,
  (url) => fetcher.get(url).then(res => res.steps),
  { revalidateOnFocus: false, suspense }
)

export const saveDistributions = ({ fetcher, authentication }) => async ({ order_id, distributions }) => {
  return await fetcher.post(`/processes/orders/${order_id}/distributions`, { distributions })
}
