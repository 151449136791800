
import { usePaginatedSWR, useSWR } from '../hooks'

export const list = ({ fetcher }) => ({filter = {}, initialPage = 1, initialPageSize = 10}) => usePaginatedSWR(
  '/global/installation_locations', filter, initialPage, initialPageSize, (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, ...filter } })
)

export const get = ({ fetcher }) => (id) => useSWR(id && `/global/installation_locations/${id}`,
  (url) => fetcher.get(url).then(res => res),
  { revalidateOnFocus: false }
)

export const create = ({ fetcher }) => installationLocation => fetcher.post('/global/installation_locations/', installationLocation)

export const update = ({ fetcher }) => async installationLocation => {
  await fetcher.put(`/global/installation_locations/${installationLocation.id}`, installationLocation)
}

export const remove = ({ fetcher }) => id => fetcher.delete(`/global/installation_locations/${id}`)

export const deleteMask = ({ fetcher }) => id => fetcher.delete(`/global/installation_locations/${id}/mask`)

export const upload3dImage = ({ fetcher }) => async (equipment, image3d) => {
  await fetcher.put(`/global/installation_locations/${equipment.id}/image3d`, image3d)
}

export const delete3dImage = ({ fetcher }) => id => fetcher.delete(`/global/installation_locations/${id}/image3d`)

export const updateUploadInfo = ({ fetcher }) => (data) => fetcher.put(`/global/installation_locations/${data.id}/${data.typeUpdate}`, {url: data.url} ).then(res => res)

export const uploadS3 = ({ fetcher }) => (data) =>{
  return fetcher.post(`/global/upload_s3`, { id: data.id, type: data.type, extension: data.extension})
  .then(async(res) => {
    // eslint-disable-next-line no-unused-vars
    const result = await putS3(res.url, data)
    return res
  })
}

const putS3 = async (uri, data) => {
  return await fetch(uri, {
    method: 'PUT',
    headers: {
      'Content-Type': '',
      'Accept': '*/*'
    },
    body: data.fileRaw
  }).then(res => res);
}
