import { usePaginatedSWR, useSWR } from '../hooks'

const defaultFilter = {}

export const list = ({ fetcher }) => ({filter = defaultFilter, initialPage = 1, initialPageSize = 10} = {}) => usePaginatedSWR(
  '/global/device_models', filter, initialPage, initialPageSize,
  (url, page, pageSize) => fetcher.get(url, { params: { page_size: pageSize, page_index: page - 1, ...filter } })
)

export const activeList = ({ fetcher }) => () => useSWR('/global/device_models?active=1', (url) => fetcher.get(url).then(res => res.device_models), { revalidateOnFocus: false })

export const get = ({ fetcher }) => (id) => useSWR(id && `/global/device_models/${id}`,
  (url) => fetcher.get(url).then(res => res.response),
  { revalidateOnFocus: false }
)

export const update = ({ fetcher }) => async (deviceModel) => {
  const data = {
    ...deviceModel
  }
  await fetcher.put(`/global/device_models/${deviceModel.id}`, data)
}

export const create = ({ fetcher }) => deviceModel => fetcher.post('/global/device_models', deviceModel)

export const remove = ({ fetcher }) => deviceModel => fetcher.delete(`/global/device_models/${deviceModel.id}`)
