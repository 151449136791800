const hostname = window.location.hostname;
let client;
let whitelabel;
if (process.env.REACT_APP_OVERRIDE_CLIENT) {
  const [clientLabel] = process.env.REACT_APP_OVERRIDE_CLIENT.split(".");
  client = clientLabel;
  whitelabel = process.env.REACT_APP_OVERRIDE_CLIENT;
  console.log(client, whitelabel);
} else {
  const parts = hostname.split(".");
  client = parts[0];
  if (parts.length > 3) {
    whitelabel = `${client}.${parts[1]}`;
  } else {
    whitelabel = client;
  }
}

export const getClient = () => ({ whitelabel, client });
export const useClient = getClient;
