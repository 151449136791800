import React from 'react'
import styled from 'styled-components/macro'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Container = styled.div`
    display: flex;
    height: 100px;
    align-items: center;
    color: white;

    div {
        line-height: 15px;

        h1 {
            color: white;
            font-size: 16px;
        }
    }
`

const AlertIcon = styled(FontAwesomeIcon).attrs(props => ({ icon: faInfoCircle }))`
  font-size: 40px;
  margin: 0 20px;
`

export const Footer = (props) => {
    const { title, description } = props

    return (
        <></>
    )
}
